import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchGroups, getUsers } from '../../../Services/Approval';
import { editDraftData, getDraftById } from '../../../Services/Draft';
import { draftStatus } from '../../State/DraftState';
import {
  automaticallyDroppedMsg,
  droppedMsg,
  sortListAlphabetical,
} from '../Helper';
import { restrictionStatus } from '../StaticData';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  draftId: GridRowId;
}

const EditDraftGroup: React.FC<Props> = ({ open, onClose, draftId }) => {
  const [allGroups, setAllGroups] = useState<any>([]);
  const [fixedValues, setFixedValues] = useState<any>([]);

  const { data: draftData, isPending: loadingDraft } = useQuery({
    queryKey: ['get_draft_by_id', draftId],
    queryFn: () => getDraftById(draftId as string),
    enabled: !!draftId,
  });

  const { data: authorData, isPending: loadingAuthors } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => response?.users || [],
  });

  const methods = useForm();
  const { handleSubmit, control, reset } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  useEffect(() => {
    reset({
      groups: draftData?.groups || [],
    });
  }, [draftData, reset]);

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
  });

  useEffect(() => {
    const allApproversId =
      draftData?.approvers?.map((approver: any) => approver?.id) || [];

    const allCollaboratorsId =
      draftData?.collaborators?.map((collaborator: any) => collaborator?.id) ||
      [];

    const allOwnersId = draftData?.owners?.map((owner: any) => owner?.id) || [];

    const allPreSignatoriesId =
      draftData?.pre_signatories?.map(
        (preSignatory: any) => preSignatory?.id
      ) || [];

    const allSignatoriesId =
      draftData?.signatories?.map((signatory: any) => signatory?.id) || [];

    const allAssigneeIds = [
      ...allApproversId,
      ...allCollaboratorsId,
      ...allOwnersId,
      ...allPreSignatoriesId,
      ...allSignatoriesId,
    ];

    const allAssigneeGroups = authorData
      ?.filter((item: any) => allAssigneeIds.includes(item?.id))
      ?.map((userData: any) => userData.groups)
      ?.flat();

    const filterGroups = [...new Set(allAssigneeGroups)]?.filter((group: any) =>
      draftData?.groups?.includes(group)
    );

    const filterGroupList = groupListData?.filter((group: any) =>
      filterGroups.includes(group?.name)
    );
    setFixedValues(filterGroupList);
    setAllGroups(filterGroups);
  }, [draftData, authorData, groupListData]);

  const { mutate: updateDraftData, isPending: loadingUpdate } = useMutation({
    mutationKey: ['edit-groups'],
    mutationFn: editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      enqueueSnackbar('Successfully updated Groups!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Groups!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = useCallback(
    (data: any) => {
      if (data?.groups) {
        const payload = {
          id: draftId,
          body: {
            id: draftId,
            ...data,
          },
        };
        updateDraftData(payload);
      }
    },
    [draftId, updateDraftData]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Edit Group"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: '500px', padding: '30px 10px' }}>
            <RISelectComponent
              name="groups"
              control={control}
              label="Prescribing Department *"
              valueKey="name"
              options={sortListAlphabetical(groupListData)}
              loading={groupLoading || loadingDraft || loadingAuthors}
              isMultiselect={true}
              disableOptionCondition={(group: any) =>
                allGroups?.includes(group?.name)
              }
              fixedValues={fixedValues}
              renderCustomComponent={(value: any) =>
                value?.id && value?.name ? (
                  <CustomChip key={value?.id} label={value?.name} />
                ) : null
              }
              disableClearable={true}
            />
            <Tooltip
              title={
                draftData?.status === draftStatus?.Draft_Dropped
                  ? droppedMsg
                  : draftData?.status === draftStatus?.Automatically_Dropped
                    ? automaticallyDroppedMsg
                    : ''
              }
              arrow
            >
              <Stack>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={loadingUpdate}
                  disabled={restrictionStatus?.includes(draftData?.status)}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default EditDraftGroup;
