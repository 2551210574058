import '../Design/draft.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import CreateDraftDialog from './CreateDraft/CreateDraftDialog';
import EditDraftGroup from './CreateDraft/EditDraftGroupModal';
import DateRangeModel from './DateRangeModel';
import DraftTable from './DraftTable';
import EditDraftCategory from './EditDraftCategory';
import {
  allDraftsTabs,
  DrafTableTabs,
  dropNotShowingStatus,
  myDraftTabs,
  myStatisticTabs,
  statisticTabs,
} from './StaticData';
import StaticTab from './TableTabList';
import { useUserData } from '../../App/Component/UserDataProvider';
import { IObject } from '../../interfaces/IObject';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import DeleteIcon from '../../RiverusUI/Components/Icons/DeleteIcon';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import {
  editContract,
  fetchContractById,
} from '../../Services/DocumentLibrary';
import {
  deleteDraft,
  dropUnDropDraft,
  exportStylusExcel,
  fetchDrafts,
  fetchStatistics,
} from '../../Services/Draft';
import {
  getAuthCredentials,
  getSignAccessToken,
  updateWithSignRefreshToken,
} from '../../Services/signatureTab';
import { draftStatus } from '../State/DraftState';

const initialFilter = {
  approval: null,
  contract: null,
  created_by: null,
  groups: null,
  contractType: null,
  contract_category: null,
};

const DraftComponent = () => {
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [activeTab, setActiveTab] = useState('all');
  const [activeHeader, setActiveHeader] = useState<string>('total');
  const [statistics, setStatistics] = useState(statisticTabs);
  const [myStatistics, setMyStatistics] = useState(myStatisticTabs);
  const [openCreateDraft, setOpenCreateDraft] = useState<boolean>(false);
  const [openAddGroup, setOpenAddGroup] = useState<boolean>(false);
  const [openAddCategory, setOpenAddCategory] = useState<boolean>(false);
  const [openDropUndrop, setOpenDropUndrop] = useState<boolean>(false);
  const [draftTableKey, setDraftTableKey] = useState<number>(0);
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [authCode, setAuthCode] = useState<string>();
  const [signAccessTokenData, setSignAccessTokenData] = useState<any>();
  const [signRefreshTokenData, setSignRefreshTokenData] = useState<any>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isOpenDateRange, setIsOpenDateRange] = useState<boolean>(false);
  const [dateRangeParams, setDateRangeParams] = useState<any>({
    start: null,
    end: null,
  });
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [refreshKey, setRefreshKey] = useState(false);

  const qs = require('querystring');

  const { user_id, savedSignRefreshToken, userIsAdmin } = useUserData();

  const { search } = useLocation();
  const queryParams: any = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsRenewed = queryParams.get('is_renewed');
  const queryParamsContractId = queryParams.get('contract_id');

  useEffect(() => {
    if (queryParamsRenewed === 'true') {
      setOpenCreateDraft(true);
    } else {
      setOpenCreateDraft(false);
    }
  }, [queryParamsRenewed]);

  const signRefreshToken = React.useMemo(
    () => savedSignRefreshToken,
    [savedSignRefreshToken]
  );

  const saveSignAccessToken = React.useCallback((response: any) => {
    window.sessionStorage.setItem(
      'signAccessToken',
      response.data.access_token
    );
  }, []);

  const queryClient = useQueryClient();

  const { data: umbrellaContractData } = useQuery({
    queryKey: ['get_contract_data_by_id', queryParamsContractId],
    queryFn: () => fetchContractById(queryParamsContractId),
    enabled: !!queryParamsContractId,
  });

  const { mutate: updateContract } = useMutation({
    mutationFn: (id: string) =>
      editContract({
        id: id,
        body: {
          is_renewed: umbrellaContractData?.refcontract_id
            ? 'Latest_Renewed'
            : 'Not_Renewed',
          renewal_draft_type: '',
        },
      }),
    onError: () => {
      enqueueSnackbar('Failed to update the contract', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleClose = () => {
    setOpenCreateDraft(false);
  };

  const { mutate: deleteDraftMutation, isPending: loadingDelete } = useMutation(
    {
      mutationFn: deleteDraft,
      onSuccess: () => {
        const renewalDrafts = selectedRowsData?.filter(
          (row: any) => row?.renewal_contract_id
        );
        const underRenewalContractIds = renewalDrafts.map(
          (draft: any) => draft?.renewal_contract_id
        );

        underRenewalContractIds?.forEach((id: string) => {
          updateContract(id);
        });
        // Invalidate and refetch
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
        queryClient.invalidateQueries({
          queryKey: ['draft_statistics'],
        });
        setOpenDeleteModal(false);
      },
    }
  );

  const { mutate: sendExcel, isPending: excelSending } = useMutation({
    mutationKey: ['send-stylus-excel', activeHeader],
    mutationFn: () => {
      const queryParams = [];

      // Add date range params
      if (dateRangeParams?.start && dateRangeParams?.end) {
        queryParams.push(`start_date=${dateRangeParams?.start}`);
        queryParams.push(`end_date=${dateRangeParams?.end}`);
      }

      // Add filter type param
      if (activeHeader && activeHeader !== 'total') {
        queryParams.push(`filter_type=${activeHeader}`);
      }

      // Add filters
      for (const key in filters) {
        if (filters[key]) {
          queryParams.push(`${key}=${filters[key]}`);
        }
      }

      // Add activeTab params
      if (activeTab && activeTab !== 'all') {
        const tabParam =
          activeTab === 'my_drafts'
            ? myDraftTabs.includes(activeHeader)
              ? ''
              : `filter_type=${activeTab}`
            : `status=${activeTab}`;
        if (tabParam) queryParams.push(tabParam);
      }

      // Combine all query params
      const params = queryParams.length ? `?${queryParams.join('&')}` : '';

      return exportStylusExcel(params);
    },
    onSuccess: () => {
      enqueueSnackbar('The report will be delivered to your inbox shortly', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: dropUnDropMutation, isPending: loadingDrop } = useMutation({
    mutationFn: dropUnDropDraft,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      queryClient.invalidateQueries({
        queryKey: ['get_selected_draft_by_id'],
      });
      setOpenDropUndrop(false);
    },
  });

  const showNoData = useMemo(() => {
    return (
      (activeHeader === 'my_signature_pending' ||
        activeHeader === 'signature_pending' ||
        activeHeader === 'my_signed_draft' ||
        activeHeader === 'signed_draft') &&
      activeTab === 'my_checklist_tasks'
    );
  }, [activeHeader, activeTab]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'drafts',
      activeTab,
      pageNumber,
      filters,
      sorting,
      activeHeader,
      draftTableKey,
      dateRangeParams,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let filterTypeParam = '';
      if (activeHeader && activeHeader !== 'total') {
        filterTypeParam = `&filter_type=${activeHeader}`;
      }
      let sortingParam = '';

      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }

      let dateParams = '';
      if (dateRangeParams?.start && dateRangeParams?.end) {
        dateParams = `&start_date=${dateRangeParams?.start}&end_date=${dateRangeParams?.end}`;
      }

      let params = '';
      const page = pageNumber + 1;
      params = `${'&page=' + page}${sortingParam}${filterParam}${filterTypeParam}${
        activeTab !== 'all' ? `${'&sub_filter_type='}` + activeTab : ''
      }${dateParams}`;
      return fetchDrafts(params);
    },
    enabled: !showNoData,
  });

  const { data: draft_statistics } = useQuery({
    queryKey: ['draft_statistics'],
    queryFn: fetchStatistics,
  });

  useEffect(() => {
    if (draft_statistics) {
      const updatedMyStatistics = myStatistics.map((data: any) => ({
        ...data,
        count: draft_statistics[data.apiKey] || data.count,
      }));

      const updatedStatistics = statistics.map((data: any) => ({
        ...data,
        count: draft_statistics[data.apiKey] || data.count,
      }));

      setMyStatistics(updatedMyStatistics);
      setStatistics(updatedStatistics);
    }
  }, [draft_statistics]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setAuthCode(urlParams.get('code') || undefined);
  }, []);

  const { data: authCredentialsData } = useQuery({
    queryKey: ['auth_credentials'],
    queryFn: getAuthCredentials,
  });

  useQuery({
    queryKey: ['access_token', signAccessTokenData],
    queryFn: async () => await getSignAccessToken(signAccessTokenData),
    select: (response: any) => {
      saveSignAccessToken(response);
      window.sessionStorage.setItem(
        'signRefreshToken',
        response.data.refresh_token
      );
      enqueueSnackbar('Go to the opened draft to proceed with signing', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        //autoHideDuration: 6000,
      });
      return response;
    },
    enabled: !!signAccessTokenData,
  });

  useQuery({
    queryKey: ['refresh_token'],
    queryFn: async () => await updateWithSignRefreshToken(signRefreshTokenData),
    select: (result: any) => {
      saveSignAccessToken(result);
      return result;
    },
    throwOnError: (error: any) => {
      if (error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          // Invalidate tokens for starting again for refesh token
          window.sessionStorage.removeItem('signAccessToken');
          window.sessionStorage.removeItem('signRefreshToken');
        }
      }
      return false;
    },
    enabled: !!signRefreshTokenData,
  });

  const credentialsClient = React.useMemo(() => {
    if (authCredentialsData) {
      const clientIdData = authCredentialsData?.client_id;
      const clientSecretData = authCredentialsData?.client_secret;
      const base64Key = authCredentialsData?.client_key;
      const decryptData = (encryptedData: string) => {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
        const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
        const ciphertext = CryptoJS.lib.WordArray.create(
          dataBytes.words.slice(4)
        );
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(ciphertext.toString()),
        });
        const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
          iv: CryptoJS.enc.Hex.parse(iv.toString()),
        });
        const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
        return decryptedText;
      };
      return {
        id: decryptData(clientIdData),
        secret: decryptData(clientSecretData),
      };
    }
  }, [authCredentialsData]);

  useEffect(() => {
    let data = {};
    const redirect_uri = process.env.REACT_APP_RIVERUS_ADOBE_REDIRECT;
    if (credentialsClient?.id && credentialsClient?.secret) {
      if (authCode) {
        data = {
          grant_type: 'authorization_code',
          client_id: credentialsClient?.id,
          client_secret: credentialsClient?.secret,
          redirect_uri: redirect_uri,
          code: authCode,
        };
        const formData = qs.stringify(data);
        setSignAccessTokenData(formData);
      } else if (signRefreshToken) {
        data = {
          grant_type: 'refresh_token',
          client_id: credentialsClient?.id,
          client_secret: credentialsClient?.secret,
          refresh_token: signRefreshToken,
        };
        const formData = qs.stringify(data);
        setSignRefreshTokenData(formData);
      }
    }
  }, [authCode, credentialsClient, signRefreshToken, qs]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumber(0);
    setActiveTab(newValue);
    setFilters({});
  };

  const handleHeaderChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    if (myDraftTabs.includes(newValue)) {
      setActiveTab('all');
    }
    if (allDraftsTabs.includes(newValue)) {
      setActiveTab('all');
    }
    setPageNumber(0);
    setActiveHeader(newValue);
    setFilters({});
  };

  const handleDelete = React.useCallback(
    (ids: GridRowId[]) => {
      if (ids.length > 0) {
        const selectedRowData = data?.results?.filter((row: any) =>
          ids.includes(row?.id)
        );
        const draftIDs = selectedRowData?.map((row: any) => row?.draftID);
        deleteDraftMutation(draftIDs);
      }
    },
    [deleteDraftMutation, data]
  );

  const handleRefresh = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['draft_statistics'],
    });
    queryClient.invalidateQueries({
      queryKey: ['author'],
    });
    setDraftTableKey(Math.random());
    setFilters(initialFilter);
    setSorting([]);
    setRefreshKey((prevKey) => !prevKey);
  }, []);

  const handleRowsSelection = (newSelectedRowIds: GridRowId[], rows: any[]) => {
    const selectedRows = rows?.filter((row: any) =>
      newSelectedRowIds?.includes(row?.id)
    );
    setSelectedRowsData(selectedRows);
    setSelectedRow(newSelectedRowIds);
  };

  const selectedRowData = useMemo(() => {
    return data?.results?.find((result: any) =>
      selectedRow.includes(result?.id)
    );
  }, [data?.results, selectedRow]);

  const renderTable = React.useCallback(
    () => (
      <DraftTable
        setSelectedRow={setSelectedRow}
        data={data}
        isLoading={isLoading || isFetching}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumber}
        setFilters={setFilters}
        setSorting={setSorting}
        filters={filters}
        handleRowsSelection={handleRowsSelection}
        activeHeader={activeHeader}
        refreshKey={refreshKey}
        showNoData={showNoData}
      />
    ),
    [data, filters, isFetching, isLoading, pageNumber]
  );

  const isOwner = useMemo(() => {
    const index = selectedRowData?.owners?.findIndex(
      (owner: any) => owner?.id === user_id
    );
    if (index > -1) {
      return true;
    }
    return false;
  }, [selectedRow, user_id]);

  const handleDropUndrop = () => {
    let payload = {
      draft_ids: selectedRow,
      drop_status: true,
    };

    if (selectedRowData?.status === draftStatus?.Draft_Dropped) {
      payload = {
        draft_ids: selectedRow,
        drop_status: false,
      };
    }

    dropUnDropMutation(payload);
  };

  const dropUnDropText = useMemo(() => {
    if (selectedRow?.length === 1) {
      return selectedRowData?.status === draftStatus?.Draft_Dropped
        ? 'Undrop'
        : 'Drop';
    }
  }, [data?.results, selectedRow]);

  const isShowDropUndrop = useMemo(() => {
    if (selectedRow?.length === 1) {
      return (
        !dropNotShowingStatus?.includes(selectedRowData?.status) &&
        (isOwner || userIsAdmin)
      );
    }
    return false;
  }, [data?.results, selectedRow]);

  const selectedMultipleRowData = useMemo(() => {
    return (
      data?.results?.filter((result: any) =>
        selectedRow?.includes(result?.id)
      ) || []
    );
  }, [data?.results, selectedRow]);

  const isSameContractType = useMemo(() => {
    const firstContractTypeId = selectedMultipleRowData[0]?.contractType?.id;
    return selectedMultipleRowData?.every(
      (row: any) => row?.contractType?.id === firstContractTypeId
    );
  }, [selectedMultipleRowData]);

  const isExecuted = useMemo(() => {
    return selectedMultipleRowData?.some(
      (row: any) => row?.status === draftStatus.CONTRACT_EXECUTED_SUCCESSFULLY
    );
  }, [selectedMultipleRowData]);

  const handleOpenDropModal = () => {
    if (selectedRow?.length === 1) {
      const message =
        !userIsAdmin && !isOwner
          ? "This action can't be performed; you must be the Owner of the draft or an Admin."
          : null;
      if (message) {
        enqueueSnackbar(message, {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }
    }
    setOpenDropUndrop(true);
  };

  const handleOpenGroupModal = () => {
    if (selectedRow?.length === 1) {
      const message =
        !userIsAdmin && !isOwner
          ? "This action can't be performed; you must be the Owner of the draft or an Admin."
          : null;
      if (message) {
        enqueueSnackbar(message, {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }
    }
    setOpenAddGroup(true);
  };

  const handleOpenCategoryModal = () => {
    if (selectedRow?.length === 1) {
      const message = isExecuted
        ? 'Contract executed successfully. Category cannot be added now!'
        : !userIsAdmin && !isOwner
          ? "This action can't be performed; you must be the Owner of the draft or an Admin."
          : null;
      if (message) {
        enqueueSnackbar(message, {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }
    }
    setOpenAddCategory(true);
  };

  const handleOpenDeleteModal = () => {
    const message = isExecuted
      ? 'Contract executed successfully; cannot be deleted!'
      : !userIsAdmin && !isOwner
        ? "This action can't be performed; you must be the Owner of the draft or an Admin."
        : null;
    if (message) {
      enqueueSnackbar(message, {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }
    setOpenDeleteModal(true);
  };

  return (
    <Box>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack
          direction="row"
          spacing="10px"
          sx={{
            width: '100%',
            overflowX: 'scroll',
            scrollbarWidth: 'none',
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              Create
            </Typography>
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'riPrimary.500',
                padding: '8px 16px',
                borderRadius: '6px',
                fontSize: '11px',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 1,
                alignItems: 'center',
                whiteSpace: 'nowrap',
                height: '4rem',
              }}
              onClick={() => {
                setOpenCreateDraft(true);
              }}
            >
              <AddCircleOutlineRoundedIcon fontSize="small" />
              <br />
              New Draft
            </Button>
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              My Drafts
            </Typography>
            <Tabs
              value={activeHeader}
              onChange={handleHeaderChange}
              aria-label="My Draft tabs"
              variant="scrollable"
              scrollButtons={false}
              sx={{ marginTop: '4px' }}
            >
              {myStatistics.map((tab: any, index: number) => (
                <StaticTab
                  key={index}
                  count={tab.count}
                  label={tab.label}
                  value={tab.value}
                  {...a11yProps(tab.value)}
                />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              All Drafts
            </Typography>
            <Tabs
              value={activeHeader}
              onChange={handleHeaderChange}
              aria-label="All Draft tabs"
              variant="scrollable"
              scrollButtons={false}
              sx={{ marginTop: '4px' }}
            >
              {statistics.map((tab: any, index: number) => (
                <StaticTab
                  key={index}
                  count={tab.count}
                  label={tab.label}
                  value={tab.value}
                  {...a11yProps(tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
            {selectedRow.length ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {selectedRow.length === 1 && isShowDropUndrop && (
                  <Button variant="text" onClick={handleOpenDropModal}>
                    {dropUnDropText} Draft
                  </Button>
                )}
                {selectedRow.length === 1 && (
                  <Stack>
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={handleOpenGroupModal}
                    >
                      Add Groups
                    </Button>
                  </Stack>
                )}
                {isSameContractType && (
                  <Stack>
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={handleOpenCategoryModal}
                    >
                      Add Category
                    </Button>
                  </Stack>
                )}
                <Stack>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={handleOpenDeleteModal}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Box>
            ) : (
              <Stack direction="column" alignItems="start">
                <IconButton onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Draft tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {DrafTableTabs.map((tab: any, index: number) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        value={tab.value}
                        {...a11yProps(tab.value)}
                      />
                    ))}
                  </Tabs>
                  <Stack direction="row">
                    <Tooltip
                      title={dateRangeParams.start ? 'Clear Date Filter' : ''}
                    >
                      <LoadingButton
                        onClick={() => setIsOpenDateRange(true)}
                        startIcon={<DateRangeIcon />}
                        sx={{
                          padding: '10px 14px',
                          margin: 0,
                          whiteSpace: 'nowrap',
                          textTransform: 'capitalize',
                        }}
                      >
                        {dateRangeParams?.start
                          ? `${dayjs(dateRangeParams.start).format('DD-MM-YYYY')} to ${dayjs(dateRangeParams.end).format('DD-MM-YYYY')}`
                          : 'Filter By Date'}
                      </LoadingButton>
                    </Tooltip>
                    <LoadingButton
                      loading={excelSending}
                      disabled={isFetching || !data?.results?.length}
                      onClick={() => sendExcel()}
                      startIcon={<ForwardToInboxIcon />}
                      sx={{
                        padding: '10px 14px',
                        margin: 0,
                        whiteSpace: 'nowrap',
                        textTransform: 'capitalize',
                      }}
                    >
                      Email Excel
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Box>
          {renderTable()}
        </Box>
      </Stack>
      {openCreateDraft && (
        <CreateDraftDialog
          open={openCreateDraft}
          onClose={handleClose}
          setFilters={setFilters}
          setSorting={setSorting}
          initialFilter={initialFilter}
          setRefreshKey={setRefreshKey}
        />
      )}
      {openAddGroup && (
        <EditDraftGroup
          open={openAddGroup}
          onClose={() => setOpenAddGroup(false)}
          draftId={selectedRow[0]}
        />
      )}
      {openAddCategory && (
        <EditDraftCategory
          open={openAddCategory}
          onClose={() => setOpenAddCategory(false)}
          selectedRow={selectedRow}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title="Delete Draft"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => handleDelete(selectedRow)}
          buttonLoading={loadingDelete}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected draft will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {openDropUndrop && (
        <ReusableConfirmationModal
          open={openDropUndrop}
          onClose={() => setOpenDropUndrop(false)}
          title={`${dropUnDropText} Draft`}
          cancelBtnText="Cancel"
          confirmBtnText={`${dropUnDropText} Draft`}
          onConfirm={handleDropUndrop}
          buttonLoading={loadingDrop}
        >
          <Stack spacing={2}>
            <Typography>
              Are you sure you want to {dropUnDropText} this draft?
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {isOpenDateRange && (
        <DateRangeModel
          open={isOpenDateRange}
          onClose={() => setIsOpenDateRange(false)}
          title="First request date range"
          setDateRangeParams={setDateRangeParams}
          dateRange={dateRangeParams}
        />
      )}
    </Box>
  );
};

export default DraftComponent;
