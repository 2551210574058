import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ApprovalTab from './Approval/Approval';
import ChecklistTab from './ChecklistTab/ChecklistTab';
import ClauseLibraryTab from './ClauseLibraryTab/ClauseLibraryTab';
import CompleteDraft from './CompleteDraft/CompleteDraft';
import UploadDraftDialog from './CompleteDraft/UploadDraftDialog';
import DeviationTab from './DeviationTab/DeviationTab';
import GenerateDraftDialog from './DeviationTab/GenerateDraftDialog';
import DraftViewAlert from './DraftViewAlert';
import {
  automaticallyDroppedMsg,
  convertPDF,
  droppedMsg,
  retryWithMaxAttempts,
} from './Helper';
import { CLAUSE_RENDERING_ORDER } from './InsightsTab/constants';
import InsightsTab from './InsightsTab/InsightsTab';
import LoadingInsights from './InsightsTab/LoadingInsights';
import PdfView from './PdfView';
import SignatureTab from './SignatureTab/SignatureTab';
import { restrictionStatus, version0Tabs, version1Tabs } from './StaticData';
import TemplateErrorAlert from './TemplateErrorModal';
import { useUserData } from '../../App/Component/UserDataProvider';
import ClauseLibrary from '../../Approvals/Component/ClauseLibrary/ClauseLibrary';
import { removeSessionStorage } from '../../Authentication/Actions/authentication';
import { isRiverusAdmin } from '../../DataDictionary/DDUtils';
import ReminderDraftCreatedModal from '../../DocumentView/Component/Reminders/ReminderDraftCreatedModal';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import DrawerComponent from '../../RiverusUI/Components/DrawerComponent';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import DraftHeader from '../../RiverusUI/Header/DraftHeader';
import DropdownButton from '../../RiverusUI/Header/DropdownButton';
import { riPrimary } from '../../RiverusUI/Theme/colors';
import { getUsers } from '../../Services/Approval';
import {
  createDraft,
  draftDeviation,
  editDraftData,
  fetchChecklistData,
  fetchKDPTagDeviations,
  fetchMergeTagDeviations,
  fetchOptionalFields,
  fetchReferenceDocument,
  getDraftApprovals,
  getDraftById,
  getPreSignatoryApprovers,
  getS3PresignedUrl,
  getS3ReferencePresignedURL,
  send_Email,
  upload_file_in_s3_bucket,
} from '../../Services/Draft';
import { fetchClauseTerm, fetchClauseTypes } from '../../Services/Insights';
import {
  getBlobDocument,
  getBlobPdf,
  loadFile,
} from '../../Templates/Component/DocGenerate';
import { draftStatus } from '../State/DraftState';

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  paddingX: '15px',
};

const disabledButton = {
  color: 'rgba(0, 0, 0, 0.26)',
  boxShadow: 'none',
  background: 'rgba(0, 0, 0, 0.12)',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    background: 'rgba(0, 0, 0, 0.12)',
  },
};

const EditDocumentSaveMenu: any[] = [
  {
    value: 'save_draft',
    label: 'Save Draft',
    startIcon: <SaveOutlinedIcon />,
  },
  {
    value: 'save_and_commit',
    label: 'Save and commit',
    startIcon: <CommitOutlinedIcon />,
  },
];

const SparkMD5 = require('spark-md5');
export let loadingOfInsightList = false;

const ViewDraft: React.FC = () => {
  const { id, version } = useParams<{
    id: string;
    version: string;
  }>();
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const queryParams: any = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsValue = queryParams.get('isDraftUpdated');
  const queryParamsActiveTab = queryParams.get('isActiveTab');
  const signatory_id = queryParams.get('signatory_id');

  const isDraftUpdated = React.useMemo(() => {
    if (queryParamsValue) {
      return queryParamsValue === 'true' ? true : false;
    } else {
      return true;
    }
  }, [queryParamsValue]);

  const [addedSignatureFields, setAddedSignatureFields] =
    useState<boolean>(false);
  const [dropPoint, setDropPoint] = useState<any>();
  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>('complete_draft');
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [openClauseLibrary, setOpenClauseLibrary] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [instance, setInstance] = useState<any>();
  const [completeDraftFormData, setCompleteDraftFormData] = useState<any>([]);
  const [isFieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [openUploadDraftDialog, setOpenUploadDraftDialog] =
    useState<boolean>(false);

  const [versionType, setVersionType] = useState<string>('major');
  const [isOpenMergeTagDialog, setIsOpenMergeTagDialog] =
    useState<boolean>(false);
  const [falseMergeTags, setFalseMergeTags] = useState<any[]>([]);
  const [openCommitDraftModal, setOpenCommitDraftModal] =
    useState<boolean>(false);
  const [openGenerateDraftModal, setOpenGenerateDraftModal] =
    useState<boolean>(false);
  const [openMoveForwardDraftModal, setOpenMoveForwardDraftModal] =
    useState<boolean>(false);
  const [confirmationDescription, setConfirmationDescription] =
    useState<string>('');
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [deviationType, setDeviationType] = useState<string>('');
  const [saveDraftLoading, setSaveDraftLoading] = useState<boolean>(false);
  const [insightsStatus, setInsightsStatus] = useState<any>({
    status: 'Done',
    progress: 100,
  });
  const [dropDownList, setDropDownList] = useState<any[]>([]);
  const [viewDocDetails, setViewDocDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDraftLockedAlert, setShowDraftLockedAlert] =
    useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState<string>('Commit Draft');
  const [fileLink, setFileLink] = useState<string>();
  const [contractData, setContractData] = useState<any>();
  const [fetchingOptionalField, setFetchingOptionalField] =
    useState<boolean>(false);
  const [templateError, setTemplateError] = useState<any | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  // login user data
  const { user_data, user_id } = useUserData();

  const queryReminderType = queryParams.get('reminder_type');

  useEffect(() => {
    let ws = new WebSocket(
      process.env.REACT_APP_RIVERUS_CLM_WS_API +
        `ws/kdp_status/?token=${user_id}`
    );
    ws.onclose = () => {
      ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/kdp_status/?token=${user_id}`
      );
    };
    ws.onmessage = (event) => {
      const insights_status: any = JSON.parse(event.data)['message'];
      insights_status.map((data: any) => {
        if (data.id === id && data.type === 'stylus') {
          setInsightsStatus({
            status: data.kdp_status,
            progress: parseInt(data.percentage),
          });
        }
      });
    };
  }, [id, user_id]);

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => {
      const users = response?.users || [];
      return users.map((user: any) => ({
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      }));
    },
  });

  const { data: draftData } = useQuery({
    queryKey: ['get_draft_by_id', id],
    queryFn: () => getDraftById(id as string),
    select: (response: any) => {
      return response;
    },
    enabled: !!id,
  });

  const canCommit = useMemo(() => {
    const getDraftOwner = draftData?.owners?.find(
      (item: any) => item?.id === user_id
    );
    if (getDraftOwner) {
      return true;
    } else {
      return false;
    }
  }, [draftData?.owners, user_id]);

  const isDraftDropped = useMemo(
    () => restrictionStatus?.includes(draftData?.status),
    [draftData?.status]
  );

  const draftId =
    draftData?.createFrom === 'template' && draftData?.version === 1
      ? draftData?.draftID
      : draftData?.id;

  const { data: getDraftCheckList, isLoading: isLoadingCheckList } = useQuery({
    queryKey: ['draft_checkLists', draftData?.draftID],
    queryFn: () => fetchChecklistData(draftData?.draftID as string),
    select: (response: any) => {
      const filterNotDeletedItem = response?.results?.filter(
        (item: any) => !item?.deleted_status
      );
      return filterNotDeletedItem;
    },
    enabled: !!draftId,
  });

  const { data: optionalFields, isFetching: optionalFieldsFetching } = useQuery(
    {
      queryKey: ['optional_fields', draftData?.id],
      queryFn: () => {
        setFetchingOptionalField(true);
        return fetchOptionalFields(draftData?.id as string);
      },
      select: (response: any) => {
        return response?.results || [];
      },
      enabled: activeTab === 'complete_draft' && !!draftData?.id,
    }
  );

  const { data: referenceDocData } = useQuery({
    queryKey: ['reference_doc_data', id, draftData?.draftID],
    queryFn: () => fetchReferenceDocument(draftData?.draftID as string),
    select: (response: any) => response?.results,
    enabled: !!draftData?.draftID,
  });

  const { data: accessUrl } = useQuery({
    queryKey: [
      'view_reference_document_link',
      id,
      draftData?.link,
      draftData?.template_link,
    ],
    queryFn: () => {
      const payload = {
        file_type: 'view_document',
        link: draftData?.link || draftData?.template_link,
      };
      return getS3ReferencePresignedURL({ body: payload });
    },
    select: (response: any) => {
      return response?.response?.presigned_url;
    },
    enabled: !!(draftData?.link || draftData?.template_link),
  });

  useEffect(() => {
    if (accessUrl) {
      setFileLink(accessUrl);
    }
  }, [accessUrl]);

  useEffect(() => {
    if (draftData?.is_locked) {
      setShowDraftLockedAlert(true);
    }
  }, [draftData?.is_locked]);

  const { data: approvalData } = useQuery({
    queryKey: ['draft_approvals', draftData?.id, version],
    queryFn: async () => {
      const approval_type = draftData?.pre_signatories?.length
        ? 'pre_signatory_approvers'
        : 'deviation_approvers';
      const type = version === '0' ? 'requisition_approvers' : approval_type;
      const response = await getDraftApprovals(draftData?.id as string, type);
      return response?.results;
    },
    enabled: !!draftData?.id,
  });

  const disableGenerateButton = useMemo(() => {
    let disabled = false;
    if (approvalData?.length) {
      approvalData.map((approver: any) => {
        if (approver.approvers.length) {
          const approvedBy = approver.approvers.filter(
            (data: any) => data.status === 'approved'
          );
          if (approver.approval_sequence === 'any') {
            if (approvedBy.length) {
              disabled = false;
            } else {
              disabled = true;
            }
          } else {
            if (approvedBy.length === approver.approvers.length) {
              disabled = false;
            } else {
              disabled = true;
            }
          }
        } else {
          disabled = false;
        }
        return disabled;
      });
    }
    return disabled;
  }, [approvalData]);

  const editModeDisabled = useMemo(() => {
    if (
      viewDocDetails?.access_url?.includes('policy_note') ||
      viewDocDetails?.access_url?.includes('draft_notes') ||
      viewDocDetails?.access_url?.includes('note_for_approval')
    ) {
      return true;
    }
    return false;
  }, [viewDocDetails]);

  const collaboratorDisabled = useMemo(() => {
    if (
      draftData?.status?.toLowerCase().includes('signature') ||
      draftData?.drop_status?.toLowerCase().includes('signature') ||
      draftData?.status
        ?.toLowerCase()
        .includes('contract executed successfully') ||
      editModeDisabled
    ) {
      return true;
    }
    return false;
  }, [draftData?.status, editModeDisabled]);

  useEffect(() => {
    if (parseInt(version as string) === 0 && draftData?.id) {
      if (
        draftData?.createFrom === 'template' ||
        (draftData?.created_from_email && draftData?.template_link)
      ) {
        setTabs([
          { label: 'Complete Draft', value: 'complete_draft', notIn: 1 },
          ...version0Tabs,
        ]);
        setActiveTab('complete_draft');
      } else {
        setTabs(version0Tabs);
        setActiveTab('checklist');
      }
    } else if (
      (draftData?.status?.toLowerCase().includes('internal draft created') ||
        draftData?.status
          ?.toLowerCase()
          .includes('counter party draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('internal draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('counter party draft created')) &&
      isDraftUpdated
    ) {
      setTabs([
        ...(!draftData?.pre_signatories
          ? [
              {
                label: 'Escalate',
                value: 'escalate',
              },
            ]
          : []),
        {
          label: 'Clause Library',
          value: 'clause_library',
        },
        ...(!(draftData?.createFrom === 'template' && draftData?.version === 1)
          ? [
              {
                label: 'Insights',
                value: 'insights',
              },
            ]
          : []),

        ...version1Tabs,
      ]);
      if (!draftData?.pre_signatories) {
        if (draftData?.createFrom === 'template' && draftData?.version === 1) {
          setActiveTab('escalate');
        } else {
          setActiveTab('insights');
        }
      } else {
        setActiveTab('collaborate');
      }
    } else if (
      (draftData?.status?.toLowerCase().includes('committed for signature') ||
        draftData?.status?.toLowerCase().includes('signature aborted') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('committed for signature') ||
        draftData?.drop_status?.toLowerCase().includes('signature aborted')) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: 'Signatures',
          value: 'signature',
        },
        {
          label: 'Checklist',
          value: 'checklist',
        },
      ]);
      setActiveTab('signature');
    } else if (
      (draftData?.status?.toLowerCase().includes('signature pending') ||
        draftData?.status
          ?.toLowerCase()
          .includes('contract executed successfully') ||
        draftData?.drop_status?.toLowerCase().includes('signature pending') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('contract executed successfully')) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: 'Signatures',
          value: 'signature',
        },
      ]);
      setActiveTab('signature');
    } else {
      setTabs(version1Tabs);
      setActiveTab('insights');
    }
  }, [version, draftData, isDraftUpdated]);

  useEffect(() => {
    if (
      queryReminderType === 'checklist' ||
      queryReminderType === 'policy_checklist' ||
      queryParamsActiveTab === 'checklist'
    ) {
      setActiveTab('checklist');
    }
    if (
      queryReminderType === 'approval' ||
      queryReminderType === 'deviation' ||
      queryReminderType === 'custom_deviation'
    ) {
      setActiveTab('collaborate');
    }
    if (queryReminderType === 'signature') {
      setActiveTab('signature');
    }
  }, [version, draftData, queryReminderType]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);

    if (queryParamsActiveTab === 'checklist') {
      navigate(pathname);
    }
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDraftDialog(false);
  };

  const handleCloseCommitDraftModal = () => {
    setOpenCommitDraftModal(false);
    setConfirmationDescription('');
    setConfirmationText('');
  };

  const handleCloseGenerateDraftModal = () => {
    setOpenGenerateDraftModal(false);
    setConfirmationDescription('');
    setConfirmationText('');
  };

  const handleCloseMoveForwardDraftModal = () => {
    setOpenMoveForwardDraftModal(false);
    setConfirmationDescription('');
    setConfirmationText('');
  };

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = uploadedFile?.[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedFile]
  );

  const { mutate: sendEmailMutation, isPending: sendingEmail } = useMutation({
    mutationKey: ['email_send'],
    mutationFn: send_Email,
    onSuccess: (response: any) => {
      if (response?.message !== 'No Emails Sent') {
        enqueueSnackbar('Email sent successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to send email!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleEmailSend = (id: string, status: string) => {
    const emailPayload = {
      draft: id,
      contract_type: '',
    };
    if (status?.toLowerCase().includes('internal draft created')) {
      emailPayload.contract_type = 'Draft Generated';
      sendEmailMutation(emailPayload);
    }
    if (status?.toLowerCase().includes('counter party draft created')) {
      emailPayload.contract_type = 'Draft Uploaded';
      sendEmailMutation(emailPayload);
    }
    if (status?.toLowerCase().includes('draft upload pending')) {
      emailPayload.contract_type = 'Approval Draft Flow';
      sendEmailMutation(emailPayload);
    }
  };

  const { mutate: presignedUrlMutation } = useMutation({
    mutationKey: ['presigned_url'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: async (response: any) => {
      removeSessionStorage('doc_file_name');
      const presignedUrl = response?.response?.presigned_url;
      setFileLink(presignedUrl);
      const type = response?.type;

      if (type === 'reference_document') {
        const fileExtension =
          presignedUrl.split('.').pop()?.split('?')[0] || 'pdf';
        fetch(presignedUrl)
          .then((r) => r.blob())
          .then((blobData) => {
            saveAs(
              blobData,
              `${draftData?.contractName + `.${fileExtension}`}`
            );
          });
      } else {
        setLoaded(true);
        if (type === 'generate_draft') {
          if (isOpenMergeTagDialog) {
            handleDialogClose();
          }
          generateDraft(presignedUrl);
        } else if (type === 'move_forward' && presignedUrl) {
          handleMoveContract(presignedUrl);
        } else if (type === 'commit_drat' && presignedUrl) {
          convertDocToPdf(presignedUrl);
        } else {
          instance?.UI.loadDocument(presignedUrl);
        }
      }
    },
    onSettled: (data) => {
      return data?.response?.presigned_url;
    },
  });

  useEffect(() => {
    const referenceFileList: any[] = [];
    if (referenceDocData?.length > 0) {
      referenceDocData.forEach((referenceFileData: any) => {
        referenceFileList.push({
          access_url: referenceFileData?.link,
          dataObject: referenceFileData,
        });
      });
    }
    const dropdownItems: any[] = [
      {
        access_url: fileLink,
        dataObject: draftData,
      },
      ...referenceFileList,
    ];

    if (draftData?.created_from_email && draftData?.template_link) {
      const templateLink = draftData?.template_link;
      const fileName = templateLink?.substring(
        templateLink?.lastIndexOf('/') + 1
      );
      dropdownItems.push({
        access_url: templateLink,
        dataObject: { file_name: fileName },
      });
    }
    setDropDownList(dropdownItems);
    setViewDocDetails({
      access_url: fileLink,
      dataObject: draftData,
    });
  }, [referenceDocData, draftData, fileLink]);

  const { mutate: updateDraftData, isPending: updatingDraft } = useMutation({
    mutationKey: ['update_draft_data'],
    mutationFn: editDraftData,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({
        queryKey: ['get_draft_by_id', id],
      });
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      if (
        response?.status === draftStatus?.DRAFT_UPLOAD_PENDING ||
        response?.status === draftStatus?.SIGNATURE_ABORTED
      ) {
        handleEmailSend(response?.id, response?.status);
      }
      if (response?.status === draftStatus?.DRAFT_UPLOAD_PENDING) {
        if (!response?.created_from_email) {
          enqueueSnackbar(
            'Thank you. Please upload new versions from version drawer',
            {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }
          );
        } else {
          enqueueSnackbar(
            'Thank you. Please upload a new version or link a template from version drawer',
            {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }
          );
        }
        navigate('/draftingreview');
      } else {
        navigate(`/draft/${response.version}/${response?.id}`);
        setLoaded(true);
        const payload = {
          file_type: 'view_document',
          link: draftData?.link || draftData?.template_link,
        };
        presignedUrlMutation({ body: payload });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const [isSending, setIsSending] = useState(false);

  const { mutate: generateMergeTagIssue, isPending: generatingMergeTagIssue } =
    useMutation({
      mutationKey: ['generate_merge_tag_issue'],
      mutationFn: draftDeviation,
      onError: () => {
        enqueueSnackbar('Failed to generate merge tag deviations!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: createDraftMutation, isPending: creatingDraft } = useMutation(
    {
      mutationKey: ['create_draft'],
      mutationFn: (payload: any) => createDraft(payload),
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries({
          queryKey: ['view_reference_document_link'],
        });
        removeSessionStorage('doc_file_name');
        const draftDetails = response;
        if (falseMergeTags?.length > 0) {
          const updatedMergeTags = falseMergeTags.map((mergeTag: any) => ({
            ...mergeTag,
            draft: mergeTag?.draft ? draftDetails?.id : mergeTag?.draft,
            draft_datapoint: mergeTag?.draft_datapoint
              ? { ...mergeTag?.draft_datapoint, draft: draftDetails?.id }
              : mergeTag?.draft_datapoint,
          }));

          generateMergeTagIssue(updatedMergeTags);
        }
        enqueueSnackbar('Draft edited successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        await handleEmailSend(draftDetails?.id, draftDetails?.status);
        if (draftDetails.signature_method === 'Adobe') {
          setIsSending(true);
        }
        const payload = {
          file_type: 'view_document',
          link: draftData?.link || draftData?.template_link,
        };
        await presignedUrlMutation({ body: payload });

        if (openCommitDraftModal) {
          handleCloseCommitDraftModal();
        }
        if (openGenerateDraftModal) {
          handleCloseGenerateDraftModal();
        }
        if (openMoveForwardDraftModal) {
          handleCloseMoveForwardDraftModal();
        }

        if (draftDetails?.status === draftStatus?.SIGNATURE_PENDING) {
          const userIsSignatory = draftDetails?.signatories?.find(
            (signatory: any) => signatory?.email === user_data?.email
          );
          if (userIsSignatory) {
            navigate(
              `/draft/${draftDetails?.version}/${draftDetails?.id}?signatory_id=${userIsSignatory?.signatory_id || userIsSignatory?.email}`
            );
          } else {
            navigate(`/draft/${draftDetails?.version}/${draftDetails?.id}`);
          }
        } else if (
          draftDetails?.status === draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY
        ) {
          setContractData(draftDetails);
        } else {
          navigate(`/draft/${draftDetails?.version}/${draftDetails?.id}`);
          if (canEdit) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to edit Draft!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  const templateUserIds = useMemo(() => {
    return optionalFields
      ?.flatMap((field: any) => field?.field_data)
      ?.filter((fieldData: any) => fieldData?.assigned_type === 'template')
      ?.map((fieldData: any) => fieldData?.user);
  }, [optionalFields]);

  const checklistUserIds = useMemo(() => {
    return getDraftCheckList
      ?.filter((checklist: any) => checklist?.assigned_type === 'checklist')
      ?.map((checklist: any) => checklist?.user);
  }, [getDraftCheckList]);

  const filteredCollaborators = useMemo(() => {
    return draftData?.collaborators
      ?.filter((collaborator: any) => {
        const isInChecklist = checklistUserIds?.includes(collaborator?.id);
        const isInTemplate = templateUserIds?.includes(collaborator?.id);
        return isInChecklist || (isInChecklist && isInTemplate);
      })
      ?.map((collaborator: any) => collaborator?.id);
  }, [draftData, checklistUserIds, templateUserIds]);

  const { mutate: uploadDocInS3Bucket, isPending: uploadingDoc } = useMutation({
    mutationKey: ['upload_document_in_S3_bucket', versionType],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: (response: any) => {
      let mergeTagApprovers: any = [];
      if (draftData?.createFrom === 'template' && draftData?.version === 0) {
        const approvers = falseMergeTags
          ?.map((approver: any) => approver?.condition?.approvers)
          .flat();
        mergeTagApprovers = Array.from(
          new Set(approvers?.map((approver: any) => approver?.id))
        );
      }

      const draftPayload = {
        version: draftData?.version + 1,
        link: response?.file_path,
        version_type: versionType,
        draftID: draftData?.draftID,
        collaborators: filteredCollaborators,
        approvers: mergeTagApprovers || [],
        owners: draftData?.owners.map((data: any) => data.id),
        creator: draftData?.creator?.id ? draftData?.creator?.id : '',
        contractType: draftData?.contractType.id,
        contractName: draftData?.contractName,
        groups: draftData?.groups,
        createFrom: draftData?.createFrom,
        status: canEdit ? draftData?.status : status,
        earlier_draft_link: draftData?.earlier_draft_link,
        executed_contract_link: draftData?.executed_contract_link,
        support_document_link: draftData?.support_document_link,
        signatories: draftData?.signatories,
        old_signatories: draftData?.old_signatories,
        contract_category: draftData?.contract_category?.id,
        reference_no: draftData?.reference_no,
        renewal_contract_id: draftData?.renewal_contract_id
          ? draftData?.renewal_contract_id
          : '',
      };
      createDraftMutation(draftPayload);
      setLoaded(false);
      handleCloseUploadDialog();
    },
    onError: () => {
      enqueueSnackbar('Failed to upload the document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadDocument, isPending: loadingUploadDoc } = useMutation({
    mutationKey: ['upload_draft_document', uploadedFile],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response: any) => {
      if (response) {
        setIsLoading(false);
        const file = uploadedFile;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };

          if (response) {
            uploadDocInS3Bucket({
              presignedPostData: response.presigned_url,
              file: file,
              onHandleFileProgress: onHandleFileProgress,
            });
          }
        }
      }
    },
    onError: (error: any) => {
      if (error?.response?.status === 403) {
        window.location.reload();
      } else {
        enqueueSnackbar('Failed to upload document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
  });

  const generateReferenceNo = useCallback(() => {
    const field_data = completeDraftFormData?.find(
      (section: any) =>
        section.section_name.toLowerCase() === 'reference number'
    )?.field_data;
    let reference_no = '';
    field_data?.map((field: any, index: number) => {
      reference_no =
        reference_no +
        `${field.field_value}${index + 1 === field_data?.length ? '' : '/'}`;
    });
    return reference_no;
  }, [completeDraftFormData]);

  const handleTemplateError = (error: any) => {
    if (error.properties && error.properties.errors instanceof Array) {
      setTemplateError(error.properties.errors);
      const errorMessages = error.properties.errors
        .map(function (error: any) {
          return error.properties.explanation;
        })
        .join('\n');
      console.error('errorMessages', errorMessages);
    }
  };

  const generateDraft = useCallback(
    (url?: any) => {
      setIsLoading(true);

      const reference_no = generateReferenceNo();
      const flatArr = completeDraftFormData?.flatMap(
        (section: any) => section.field_data
      );
      const keyValuePair: any = flatArr?.reduce((acc: any, curr: any) => {
        const key = curr.field_name.replaceAll(/{|}/g, '');
        if (curr.data_type?.toLowerCase() === 'duration') {
          const unit = curr.field_value !== `{${curr.field}}` ? curr.unit : '';
          acc = {
            ...acc,
            [key]: curr.field_value + ' ' + unit,
          };
        } else if (curr.data_type?.toLowerCase() === 'date') {
          const date =
            curr.field_value !== `{${curr.field}}`
              ? dayjs(curr.field_value).format('DD-MM-YYYY')
              : curr.field_value;
          acc = { ...acc, [key]: date };
        } else if (
          curr.data_type.toLowerCase() === 'bulleted list' ||
          curr.data_type.toLowerCase() === 'numbered list'
        ) {
          acc = {
            ...acc,
            [key]: JSON.parse(curr.field_value).filter(
              (data: any) => data['list'] !== ''
            ),
          };
        } else acc = { ...acc, [key]: curr.field_value };
        return acc;
      }, {});

      for (const key in keyValuePair) {
        if (keyValuePair[key] === '') {
          keyValuePair[key] = '{' + key + '}';
        }
      }

      if (reference_no) {
        const payload = {
          id: draftData?.id,
          body: {
            reference_no: reference_no,
          },
        };
        updateDraftData(payload);
      }
      loadFile(url, function (error: any, content: any) {
        if (error) {
          throw error;
        }
        try {
          setIsLoading(false);
          const file: any = getBlobDocument(content, keyValuePair);
          file['name'] = draftData.contractName + '.docx';
          file['id'] = draftData.id;
          const reader = new FileReader();
          if (file) {
            setUploadedFile(file);
            reader.readAsDataURL(file);
            reader.onload = async () => {
              const hexHash = SparkMD5.hash(reader.result);
              const file_ = {
                file_hash: hexHash,
                file_size: file.size,
                type: file.type,
                template_type: file['template_type'],
                file_type: 'Contract_Draft',
                creation_type: isRiverusAdmin() ? 'system' : 'custom',
                file_name: file.name,
              };
              setStatus(draftStatus.INTERNAL_DRAFT_CREATED);
              uploadDocument(file_);
            };
          }
        } catch (error: any) {
          handleTemplateError(error);
        }
      });
    },
    [completeDraftFormData, draftData, uploadDocument, isFieldUpdated]
  );

  //  signatureTab flow

  const handleDownload = React.useCallback(() => {
    const payload = {
      file_type: 'view_document',
      link: draftData?.link || draftData?.template_link,
    };
    presignedUrlMutation({ body: payload, type: 'reference_document' });
  }, [id, draftData]);

  const convertDocToPdf = useCallback(
    async (url: string) => {
      if (!instance) return;
      const { Core } = instance;
      if (url) {
        const file = await retryWithMaxAttempts(convertPDF, 5, 5000, Core, url);
        const reader = new FileReader();
        if (file) {
          setUploadedFile(file);
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            const file_ = {
              file_hash: hexHash,
              file_name: draftData?.contractName + '.pdf',
              file_size: file?.size,
              file_type: 'committed_draft',
              creation_type: isRiverusAdmin() ? 'system' : 'custom',
              type: file?.type,
            };
            setStatus(draftStatus.COMMITTED_FOR_SIGNATURE);
            uploadDocument(file_);
          };
        }
      }
    },
    [instance]
  );

  const handleEditingModeSaveDraft = async (value: any) => {
    const { documentViewer } = instance.Core;

    const doc = documentViewer.getDocument();
    const annotationManager = documentViewer.getAnnotationManager();

    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: 'application/docx' });
    const file = blob;

    const reader = new FileReader();
    if (file) {
      setUploadedFile(file);
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const file_ = {
          file_hash: hexHash,
          file_name: draftData?.contractName + '.docx',
          file_size: file.size,
          file_type:
            value === 'save_draft' ? 'committed_draft' : 'Contract_Draft',
          creation_type: isRiverusAdmin() ? 'system' : 'custom',
          type: file.type,
        };
        setVersionType(value === 'save_draft' ? 'minor' : 'major');
        uploadDocument(file_);
      };
    }
  };

  const handleDialogClose = () => {
    setIsOpenMergeTagDialog(false);
  };

  const handleGenerateDraft = () => {
    const payload = {
      file_type: 'view_document',
      link: draftData?.link || draftData?.template_link,
    };
    presignedUrlMutation({ body: payload, type: 'generate_draft' });
  };

  const { mutate: mergeTagDeviations, isPending: isLoadingTagDeviation } =
    useMutation({
      mutationKey: ['merge_tag_deviation', id],
      mutationFn: fetchMergeTagDeviations,
      onSuccess: (response: any) => {
        setIsLoading(false);
        const allMergeTagsArray = response?.results;
        const filterData = allMergeTagsArray?.filter(
          (item: any) => item?.error === false
        );
        setFalseMergeTags(filterData);
        if (filterData?.length > 0) {
          setIsOpenMergeTagDialog(true);
        } else {
          const payload = {
            file_type: 'view_document',
            link: draftData?.link || draftData?.template_link,
          };
          presignedUrlMutation({ body: payload, type: 'generate_draft' });
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    });

  useEffect(() => {
    if (
      (draftData?.status === draftStatus.SIGNATURE_PENDING ||
        draftData?.drop_status === draftStatus.SIGNATURE_PENDING) &&
      user_data
    ) {
      const userIsSignatory = draftData?.signatories?.find(
        (signatory: any) => signatory?.email === user_data?.email
      );
      if (userIsSignatory) {
        navigate(
          `/draft/${draftData?.version}/${draftData?.id}?signatory_id=${userIsSignatory?.signatory_id || userIsSignatory?.email}`
        );
      } else {
        navigate(`/draft/${draftData?.version}/${draftData?.id}`);
      }
    }
    if (draftData?.createFrom === 'template' && draftData?.version === 1) {
      setDeviationType('merge_tag');
    } else {
      setDeviationType('kdp');
    }
  }, [draftData, user_data?.email]);

  const { data: deviationsList } = useQuery({
    queryKey: ['fetch_Kdp_deviations', draftData?.id, deviationType],
    queryFn: async () => {
      const response = await fetchKDPTagDeviations(
        draftData?.id as string,
        deviationType
      );
      return response?.results;
    },
    enabled: !!(
      draftData?.id &&
      deviationType &&
      (draftData?.status?.toLowerCase().includes('internal draft created') ||
        draftData?.status
          ?.toLowerCase()
          .includes('counter party draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('internal draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('counter party draft created'))
    ),
  });

  const { data: customDeviationList } = useQuery({
    queryKey: ['fetch_custom_deviations', draftData?.id],
    queryFn: () => fetchKDPTagDeviations(draftData?.id as string, 'custom'),
    select: (response: any) => response?.results,
    enabled: !!(
      draftData?.id &&
      (draftData?.status?.toLowerCase().includes('internal draft created') ||
        draftData?.status
          ?.toLowerCase()
          .includes('counter party draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('internal draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('counter party draft created'))
    ),
  });

  const { data: clauseType, isLoading: draftDataLoading } = useQuery({
    queryKey: ['clause_type', draftData?.id],
    queryFn: async () => await fetchClauseTypes(draftData?.id),
    select: (response: any) => {
      if (response?.length) {
        return (
          CLAUSE_RENDERING_ORDER.map((item: string) => {
            if (response?.includes(item)) {
              return item;
            }
          }) || []
        );
      }
      return [];
    },
    enabled: !!draftData?.id,
  });

  const { data: combinedData, isLoading: isLoadingCombinedData } = useQuery({
    queryKey: ['combinedData', draftData?.id, clauseType],
    queryFn: async () => {
      const combinedData = await Promise.all(
        (clauseType as string[]).map(async (type: any) => {
          if (type) {
            const termResponse = await fetchClauseTerm(draftData?.id, type);
            return {
              clauseType: type.replace(/_/g, ' '),
              clauseData: termResponse?.raw_content[type],
            };
          }
        })
      );
      return combinedData;
    },
    enabled: !!clauseType,
  });

  loadingOfInsightList = isLoadingCombinedData;

  const checkChecklistStatus = useMemo(() => {
    return getDraftCheckList?.some(
      (checklist: any) => checklist?.active_status === false
    );
  }, [getDraftCheckList]);

  const handleOpenCommitDraftModal = (title: string) => {
    setTitle(title);
    queryClient.invalidateQueries({
      queryKey: ['fetch_Kdp_deviations', draftId, deviationType],
    });
    queryClient.invalidateQueries({
      queryKey: ['fetch_custom_deviations', draftId],
    });
    queryClient.invalidateQueries({
      queryKey: ['draft_checkLists', draftData?.draftID],
    });

    const filteredDeviationsList = deviationsList?.filter((listItem: any) => {
      if (
        (listItem?.approval_status === 'approval_pending' ||
          listItem?.approval_status === null) &&
        listItem?.status === 'deviant'
      ) {
        if (
          listItem?.draft_datapoint ||
          listItem?.kdp_datapoint?.length > 0 ||
          listItem?.manual_datapoint?.length > 0
        ) {
          return listItem;
        } else if (
          !listItem?.draft_datapoint &&
          (!listItem?.manual_datapoint ||
            listItem?.manual_datapoint.length === 0) &&
          (!listItem?.kdp_datapoint || listItem?.kdp_datapoint.length === 0)
        ) {
          return listItem;
        }
      }
    });

    const filterCustomApprovalPending = customDeviationList?.filter(
      (listItem: any) => {
        if (
          listItem?.approval_status === 'approval_pending' ||
          listItem?.approval_status === null
        ) {
          if (listItem?.custom_datapoint?.length > 0) {
            return listItem;
          }
        }
      }
    );

    const isChecklistCompleted = getDraftCheckList?.some(
      (checklist: any) =>
        checklist?.active_status === false && checklist?.required
    );

    if (
      (filteredDeviationsList?.length > 0 ||
        filterCustomApprovalPending?.length > 0) &&
      !draftData?.pre_signatories
    ) {
      setConfirmationDescription(
        'Some Issues are still not resolved or require approval. Please resolve all Issues to commit this draft.'
      );
      setConfirmationText('Resolve Issues');
    } else if (isChecklistCompleted) {
      setConfirmationDescription(
        'There are certain checklist items pending to be completed, Please complete checklist.'
      );
      setConfirmationText('Complete Checklist');
    } else {
      if (title === 'Send to pre-signatories') {
        setConfirmationDescription(
          'Are you sure? You will not be able to make any changes to the draft after send to pre signatories.'
        );
        setConfirmationText('Send to pre signatories');
      } else {
        setConfirmationDescription(
          'Are you sure? You will not be able to make any changes to the draft after you commit.'
        );
        setConfirmationText('Commit draft');
      }
    }
    setOpenCommitDraftModal(true);
  };

  const handleOpenGenerateDraftModal = () => {
    const isChecklistCompleted = getDraftCheckList?.some(
      (checklist: any) =>
        checklist?.active_status === false && checklist?.required
    );

    if (isChecklistCompleted) {
      setConfirmationDescription(
        'There are certain checklist items pending to be completed, Please complete checklist.'
      );
      setConfirmationText('Complete Checklist');
    } else if (checkChecklistStatus) {
      setConfirmationDescription(
        'There are certain checklist items pending to be completed, do you wish to proceed without completing them?'
      );
      setConfirmationText('Generate draft');
    } else {
      setConfirmationDescription(
        'Are you sure you want to generate the draft?'
      );
      setConfirmationText('Generate draft');
    }
    setOpenGenerateDraftModal(true);
  };

  const handleOpenMoveForwardDraftModal = () => {
    const isChecklistCompleted = getDraftCheckList?.some(
      (checklist: any) =>
        checklist?.active_status === false && checklist?.required
    );

    if (isChecklistCompleted) {
      setConfirmationDescription(
        'There are certain checklist items pending to be completed, Please complete checklist.'
      );
      setConfirmationText('Complete Checklist');
    } else if (checkChecklistStatus) {
      setConfirmationDescription(
        'There are certain checklist items pending to be completed, do you wish to proceed without completing them?'
      );
      setConfirmationText('Move Forward');
    } else {
      setConfirmationDescription('Are you sure you want to Move Forward?');
      setConfirmationText('Move Forward');
    }
    setOpenMoveForwardDraftModal(true);
  };

  const handleMoveForwardActionBtn = () => {
    if (confirmationText === 'Move Forward') {
      if (disableGenerateButton) {
        enqueueSnackbar(
          'Please check the approval tab for pending approvals!',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        if (draftData?.createFrom === 'counter_party') {
          const payload = {
            file_type: 'view_document',
            link: draftData?.link || draftData?.template_link,
          };
          presignedUrlMutation({
            body: payload,
            type: 'move_forward',
          });
        } else {
          setIsLoading(false);
          const payload = {
            id: draftData?.id,
            body: {
              status: draftStatus?.DRAFT_UPLOAD_PENDING,
            },
          };
          updateDraftData(payload);
        }
        handleCloseMoveForwardDraftModal();
      }
    } else if (confirmationText === 'Complete Checklist') {
      setActiveTab('checklist');
      handleCloseMoveForwardDraftModal();
    }
  };

  const handleGenerateActionBtn = () => {
    if (confirmationText === 'Generate draft') {
      if (disableGenerateButton) {
        enqueueSnackbar(
          'Please check the approval tab for pending approvals!',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        setIsLoading(true);
        mergeTagDeviations(id as string);
        handleCloseGenerateDraftModal();
      }
    } else if (confirmationText === 'Complete Checklist') {
      setActiveTab('checklist');
      handleCloseGenerateDraftModal();
    }
  };

  const { mutate: fetchPreSignatory, isPending: fetchingPreSignatory } =
    useMutation({
      mutationKey: ['fetch_pre_signatories'],
      mutationFn: getPreSignatoryApprovers,
      onSuccess: (response: any) => {
        const groups = draftData?.groups || [];
        response?.results?.forEach((approver: any) => {
          const missingInDraftGroups = approver?.groups.filter(
            (item: string) => !(draftData?.groups as string[]).includes(item)
          );
          if (missingInDraftGroups?.length) {
            groups.push(...missingInDraftGroups);
          }
        });
        const payload = {
          id: draftData?.id,
          body: {
            pre_signatories: response?.results,
            groups: groups,
          },
        };
        updateDraftData(payload);
      },
    });

  const handlePreSignatories = () => {
    const payload = {
      draft_id: draftData?.id,
    };
    fetchPreSignatory(payload);
  };

  const handleConfirmActionBtn = async () => {
    const payload = {
      file_type: 'view_document',
      link: draftData?.link || draftData?.template_link,
    };
    if (confirmationText === 'Commit draft') {
      setIsLoading(true);
      await presignedUrlMutation({ body: payload, type: 'commit_drat' });
      handleCloseCommitDraftModal();
    } else if (confirmationText === 'Resolve Issues') {
      if (
        draftData?.status?.toLowerCase().includes('internal draft created') ||
        draftData?.status?.toLowerCase().includes('counter party draft created')
      ) {
        setActiveTab('escalate');
        handleCloseCommitDraftModal();
      }
    } else if (confirmationText === 'Complete Checklist') {
      setActiveTab('checklist');
      handleCloseCommitDraftModal();
    } else if (confirmationText === 'Send to pre signatories') {
      handlePreSignatories();
      handleCloseCommitDraftModal();
    }
  };

  const switchToDocxEdit = useCallback(() => {
    if (draftData?.is_locked) {
      setShowDraftLockedAlert(true);
    } else {
      setCanEdit(true);
    }
  }, [draftData?.is_locked]);

  const renderDeviation = React.useCallback(() => {
    if (insightsStatus.progress < 100) {
      return (
        <Stack
          spacing={1}
          sx={{
            height: '92%',
            overflowY: 'auto',
            backgroundColor: 'riTertiary.50',
            borderRadius: '26px',
            padding: '8px',
            mt: '8px',
          }}
        >
          <LoadingInsights
            progress={insightsStatus.progress}
            label="Automatically computing issues with the draft. They will be ready shortly."
          />
        </Stack>
      );
    } else {
      return (
        <DeviationTab
          draftData={draftData}
          instance={instance}
          handleOpenClauseLibraryDrawer={() => setOpenClauseLibrary(true)}
        />
      );
    }
  }, [draftData, insightsStatus, instance]);

  const getFileExtension = (url: string) => {
    const parts = url?.split('/');
    const fileName = parts?.[parts?.length - 1];
    return fileName?.split('.')?.pop();
  };

  const handleMoveForward = async (extension: string) => {
    const file = await getBlobPdf(instance);
    const reader = new FileReader();
    if (file) {
      setUploadedFile(file);
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const file_ = {
          file_hash: hexHash,
          file_size: file?.size,
          type: file.type,
          file_type: 'Contract_Draft',
          template_type: draftData?.['template_type'],
          creation_type: isRiverusAdmin() ? 'system' : 'custom',
          file_name: `${draftData?.contractName}.${extension}`,
        };
        setStatus(draftStatus?.COUNTER_PARTY_DRAFT_CREATED);
        uploadDocument(file_);
      };
    }
  };

  const handleMoveContract = useCallback(
    (url: string) => {
      const extension = getFileExtension(draftData?.link as string);
      if (extension === 'pdf') {
        handleMoveForward(extension);
      } else {
        const keyValuePair = {
          nameofthedraft: draftData?.contractName,
          contracttypefield: draftData?.contractType?.name,
          createcontractfromfield: 'createFrom',
          selecttemplatefield: draftData?.createFrom,
          groups: draftData?.groups,
          collaborators:
            draftData?.collaborators && draftData?.collaborators?.length
              ? draftData?.collaborators?.map(
                  (value: any) => value.first_name + ' ' + value.last_name
                )
              : [],
          signatories: draftData?.signatories?.map(
            (value: any) => value.first_name + ' ' + value.last_name
          ),
          approvers: draftData?.approvalTypes?.length
            ? draftData?.approvalTypes[0]?.approvers?.map(
                (value: any) => value.first_name + ' ' + value.last_name
              )
            : [],
        };

        loadFile(url, function (error: any, content: any) {
          if (error) {
            throw error;
          }
          try {
            const file: any = getBlobDocument(content, keyValuePair);
            file['name'] = `${draftData.contractName}.${extension}`;
            file['id'] = draftData.id;
            const reader = new FileReader();
            if (file) {
              setUploadedFile(file);
              reader.readAsDataURL(file);
              reader.onload = async () => {
                const hexHash = SparkMD5.hash(reader.result);
                const file_ = {
                  file_hash: hexHash,
                  file_size: file.size,
                  type: file.type,
                  template_type: file['template_type'],
                  file_type: 'Contract_Draft',
                  creation_type: isRiverusAdmin() ? 'system' : 'custom',
                  file_name: file.name,
                };
                setStatus(draftStatus?.COUNTER_PARTY_DRAFT_CREATED);
                uploadDocument(file_);
              };
            }
          } catch (error: any) {
            handleTemplateError(error);
          }
        });
      }
    },
    [draftData, uploadDocument, instance]
  );

  useEffect(() => {
    if (!draftData?.is_seen && draftData?.version === 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [draftData, setShowPopup]);

  useEffect(() => {
    if (draftData?.id) {
      let ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/signatures/${draftData?.id}/?token=${user_id}`
      );

      ws.onclose = () => {
        ws = new WebSocket(
          process.env.REACT_APP_RIVERUS_CLM_WS_API +
            `ws/signatures/${draftData?.id}/?token=${user_id}`
        );
      };

      ws.onmessage = (event) => {
        const refresh_status: any = JSON.parse(event.data)['refresh'];
        if (refresh_status) {
          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ['get_draft_by_id', draftData?.id],
            });
            queryClient.invalidateQueries({
              queryKey: ['view_reference_document_link'],
            });
          }, 2000);
        }
      };

      // Cleanup the WebSocket connection on component unmount
      return () => {
        ws.close();
      };
    }
  }, [draftData?.id]);

  return (
    <React.Fragment>
      <Box>
        <DraftHeader
          draftData={draftData}
          version={parseInt(version as string)}
          name={draftData?.contractName}
          handleDownload={handleDownload}
          renderAction={() =>
            version === '0' ? (
              draftData?.createFrom === 'template' ||
              (draftData?.created_from_email && draftData?.template_link) ? (
                <Tooltip
                  title={
                    draftData?.status === draftStatus?.Draft_Dropped
                      ? droppedMsg
                      : draftData?.status === draftStatus?.Automatically_Dropped
                        ? automaticallyDroppedMsg
                        : ''
                  }
                  disableHoverListener={
                    !restrictionStatus?.includes(draftData?.status)
                  }
                >
                  <Stack direction="row">
                    {activeTab === 'complete_draft' && (
                      <LoadingButton
                        loading={saveDraftLoading || updatingDraft}
                        disabled={!isDraftUpdated || isDraftDropped}
                        onClick={() =>
                          document.getElementById('save_draft_button')?.click()
                        }
                      >
                        Save Draft
                      </LoadingButton>
                    )}
                    {isDraftUpdated && activeTab === 'complete_draft' && (
                      <LoadingButton
                        id="generated_draft"
                        type="submit"
                        form="complete_draft_form"
                        variant="contained"
                        disabled={
                          !canCommit ||
                          isDraftDropped ||
                          activeTab !== 'complete_draft'
                        }
                        loading={
                          isLoadingTagDeviation ||
                          generatingMergeTagIssue ||
                          loadingUploadDoc ||
                          uploadingDoc ||
                          isLoading ||
                          updatingDraft ||
                          fetchingOptionalField
                        }
                        sx={disableGenerateButton ? disabledButton : {}}
                      >
                        Generate Draft
                      </LoadingButton>
                    )}
                    {isDraftUpdated && activeTab !== 'complete_draft' && (
                      <Tooltip
                        arrow
                        title="You can generate draft from complete draft tab only"
                      >
                        <Stack>
                          <LoadingButton
                            variant="contained"
                            disabled={
                              !canCommit ||
                              isDraftDropped ||
                              activeTab !== 'complete_draft'
                            }
                            loading={
                              isLoadingTagDeviation ||
                              generatingMergeTagIssue ||
                              loadingUploadDoc ||
                              uploadingDoc ||
                              isLoading ||
                              updatingDraft
                            }
                            sx={disableGenerateButton ? disabledButton : {}}
                            onClick={() => {
                              setActiveTab('complete_draft');
                              setTimeout(() => {
                                document
                                  .getElementById('generated_draft')
                                  ?.click();
                              }, 2000);
                            }}
                          >
                            Generate Draft
                          </LoadingButton>
                        </Stack>
                      </Tooltip>
                    )}
                  </Stack>
                </Tooltip>
              ) : (
                draftData?.status !== 'Draft Upload Pending' &&
                draftData?.drop_status !== 'Draft Upload Pending' &&
                isDraftUpdated && (
                  <Tooltip
                    title={
                      draftData?.status === draftStatus?.Draft_Dropped
                        ? droppedMsg
                        : draftData?.status ===
                            draftStatus?.Automatically_Dropped
                          ? automaticallyDroppedMsg
                          : ''
                    }
                    arrow
                    disableHoverListener={
                      !restrictionStatus?.includes(draftData?.status)
                    }
                  >
                    <Stack>
                      <LoadingButton
                        variant="contained"
                        sx={disableGenerateButton ? disabledButton : {}}
                        onClick={() => {
                          !disableGenerateButton
                            ? handleOpenMoveForwardDraftModal()
                            : '';
                        }}
                        disabled={!canCommit || isDraftDropped}
                        loading={
                          creatingDraft ||
                          loadingUploadDoc ||
                          uploadingDoc ||
                          isLoading ||
                          updatingDraft ||
                          documentLoading
                        }
                      >
                        Move forward
                      </LoadingButton>
                    </Stack>
                  </Tooltip>
                )
              )
            ) : canEdit ? (
              <DropdownButton
                options={EditDocumentSaveMenu}
                startIcon={<SaveOutlinedIcon />}
                buttonName="Save draft"
                handleDropdownChange={handleEditingModeSaveDraft}
              />
            ) : (
              (draftData?.status
                ?.toLowerCase()
                .includes('internal draft created') ||
                draftData?.status
                  ?.toLowerCase()
                  .includes('counter party draft created') ||
                draftData?.drop_status
                  ?.toLowerCase()
                  .includes('internal draft created') ||
                draftData?.drop_status
                  ?.toLowerCase()
                  .includes('counter party draft created')) && (
                <Tooltip
                  title={
                    draftData?.status === draftStatus?.Draft_Dropped
                      ? droppedMsg
                      : draftData?.status === draftStatus?.Automatically_Dropped
                        ? automaticallyDroppedMsg
                        : ''
                  }
                  arrow
                  disableHoverListener={
                    !restrictionStatus?.includes(draftData?.status)
                  }
                >
                  <Stack>
                    {draftData?.approval_type === 'pre_signatory_approval' &&
                    !draftData?.pre_signatories ? (
                      <LoadingButton
                        variant="contained"
                        sx={{
                          padding: '8px 16px',
                        }}
                        loading={
                          loadingUploadDoc ||
                          uploadingDoc ||
                          creatingDraft ||
                          isLoading ||
                          documentLoading ||
                          fetchingPreSignatory ||
                          updatingDraft
                        }
                        disabled={
                          !isDraftUpdated ||
                          !canCommit ||
                          isDraftDropped ||
                          insightsStatus?.progress < 100
                        }
                        onClick={() =>
                          handleOpenCommitDraftModal('Send to pre-signatories')
                        }
                      >
                        <SendIcon
                          sx={{ fontSize: '14px', marginRight: '8px' }}
                        />
                        Send draft to pre-signatories
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        variant="contained"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                        }}
                        loading={
                          loadingUploadDoc ||
                          uploadingDoc ||
                          creatingDraft ||
                          isLoading ||
                          documentLoading
                        }
                        disabled={
                          !isDraftUpdated ||
                          !canCommit ||
                          disableGenerateButton ||
                          isDraftDropped ||
                          insightsStatus?.progress < 100
                        }
                        onClick={() =>
                          handleOpenCommitDraftModal('Commit Draft')
                        }
                      >
                        <SendIcon
                          sx={{ fontSize: '14px', marginRight: '8px' }}
                        />
                        Commit Draft
                      </LoadingButton>
                    )}
                  </Stack>
                </Tooltip>
              )
            )
          }
          canDownload={draftData?.version !== 0}
          collaborating={draftData?.version !== 0}
          collaboratorDisabled={collaboratorDisabled}
          goBackUrl="/draftingreview"
          setCanEdit={setCanEdit}
          canEdit={canEdit}
          isLocked={draftData?.is_locked}
          showLockedModal={() => setShowDraftLockedAlert(true)}
        />
        <Grid container sx={{ mt: 2 }}>
          <Grid item sm={canEdit ? 12 : 6}>
            {loaded && fileLink && (
              <PdfView
                key={`${fileLink}-${id}`}
                draftData={viewDocDetails?.dataObject}
                fileLink={fileLink}
                setFileLink={setFileLink}
                instance={instance}
                setInstance={setInstance}
                dropPoint={dropPoint}
                setDropPoint={setDropPoint}
                addedSignatureFields={addedSignatureFields}
                setAddedSignatureFields={setAddedSignatureFields}
                canEdit={canEdit}
                handleOpenClauseLibraryDrawer={() => setOpenClauseLibrary(true)}
                referenceDocData={dropDownList}
                setViewDocDetails={setViewDocDetails}
                setDocumentLoading={setDocumentLoading}
                internalSignatoryId={signatory_id}
              />
            )}
          </Grid>
          {!canEdit && (
            <Grid item sm={6} sx={{ paddingLeft: 3, height: '87vh' }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Draft tabs"
                variant="scrollable"
                scrollButtons={false}
                sx={innerTabStyle}
              >
                {tabs.map((tab: any, index: number) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    value={tab.value}
                    {...a11yProps(tab.value)}
                  />
                ))}
              </Tabs>
              {activeTab === 'escalate' ? (
                renderDeviation()
              ) : (
                <Stack
                  spacing={1}
                  sx={{
                    height: 'calc(86vh - 4rem)',
                    overflowY: 'auto',
                    backgroundColor: 'riTertiary.50',
                    borderRadius: '24px',
                    padding: '8px',
                    mt: '8px',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#e3e3e3',
                      borderRadius: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#996c84',
                      borderRadius: '6px',
                    },
                  }}
                >
                  {activeTab === 'complete_draft' && (
                    <CompleteDraft
                      isDraftDropped={isDraftDropped}
                      draftData={draftData}
                      setCompleteDraftFormData={setCompleteDraftFormData}
                      instance={instance}
                      setFieldUpdated={setFieldUpdated}
                      updateDraftData={updateDraftData}
                      setSaveDraftLoading={setSaveDraftLoading}
                      checklistUserIds={checklistUserIds}
                      handleOpenGenerateDraftModal={() => {
                        !disableGenerateButton
                          ? handleOpenGenerateDraftModal()
                          : '';
                      }}
                      accessUrl={fileLink}
                      optionalFields={optionalFields}
                      isFetching={optionalFieldsFetching}
                      fetchingOptionalField={fetchingOptionalField}
                      setFetchingOptionalField={setFetchingOptionalField}
                    />
                  )}
                  {activeTab === 'checklist' && (
                    <ChecklistTab
                      draftData={draftData}
                      templateUserIds={templateUserIds}
                      getDraftCheckList={getDraftCheckList}
                      isLoadingCheckList={isLoadingCheckList}
                    />
                  )}
                  {activeTab === 'clause_library' && <ClauseLibraryTab />}
                  {activeTab === 'collaborate' && (
                    <ApprovalTab draftData={draftData} />
                  )}
                  {activeTab === 'insights' &&
                    (insightsStatus?.progress < 100 ? (
                      <LoadingInsights progress={insightsStatus.progress} />
                    ) : (
                      <InsightsTab
                        draftData={draftData}
                        instance={instance}
                        isLoadingCombinedData={isLoadingCombinedData}
                        combinedData={combinedData}
                        clauseType={clauseType}
                        draftDataLoading={draftDataLoading}
                      />
                    ))}
                  {activeTab === 'signature' && (
                    <SignatureTab
                      draftData={draftData}
                      instance={instance}
                      dropPoint={dropPoint}
                      addedSignatureFields={addedSignatureFields}
                      setAddedSignatureFields={setAddedSignatureFields}
                      updateDraftData={updateDraftData}
                      createDraft={createDraftMutation}
                      editModeDisabled={editModeDisabled}
                      sendForAdobeSigning={isSending}
                      internalSignatoryId={signatory_id}
                      isContractExecuting={sendingEmail || creatingDraft}
                      updatingDraft={updatingDraft}
                      accessUrl={fileLink}
                      documentLoading={documentLoading}
                      contractData={contractData}
                      setFileLink={setFileLink}
                      authorData={authorData}
                    />
                  )}
                </Stack>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      {openUploadDraftDialog && (
        <UploadDraftDialog
          files={uploadedFile}
          open={openUploadDraftDialog}
          onClose={handleCloseUploadDialog}
          setFiles={setUploadedFile}
          loadingUploadDoc={loadingUploadDoc}
          uploadDocument={uploadDocument}
          draftID={id as string}
        />
      )}
      {isOpenMergeTagDialog && (
        <GenerateDraftDialog
          open={isOpenMergeTagDialog}
          onClose={handleDialogClose}
          mergeTagData={falseMergeTags}
          handleGenerateDraft={handleGenerateDraft}
        />
      )}
      {openCommitDraftModal && (
        <ReusableConfirmationModal
          open={openCommitDraftModal}
          onClose={handleCloseCommitDraftModal}
          onConfirm={handleConfirmActionBtn}
          title={title}
          confirmBtnText={confirmationText}
          cancelBtnText="Cancel"
        >
          <Typography>{confirmationDescription}</Typography>
        </ReusableConfirmationModal>
      )}
      {openGenerateDraftModal && (
        <ReusableConfirmationModal
          open={openGenerateDraftModal}
          onClose={handleCloseGenerateDraftModal}
          onConfirm={handleGenerateActionBtn}
          title="Generate Draft"
          confirmBtnText={confirmationText}
          cancelBtnText="Cancel"
          buttonLoading={documentLoading}
        >
          <Typography>{confirmationDescription}</Typography>
        </ReusableConfirmationModal>
      )}
      {openMoveForwardDraftModal && (
        <ReusableConfirmationModal
          open={openMoveForwardDraftModal}
          onClose={handleCloseMoveForwardDraftModal}
          onConfirm={handleMoveForwardActionBtn}
          title="Move Forward"
          confirmBtnText={confirmationText}
          cancelBtnText="Cancel"
          buttonLoading={documentLoading}
        >
          <Typography>{confirmationDescription}</Typography>
        </ReusableConfirmationModal>
      )}
      {openClauseLibrary && (
        <DrawerComponent
          open={openClauseLibrary}
          onClose={() => setOpenClauseLibrary(false)}
          drawerHeader="Replace with clause library "
        >
          <ClauseLibrary
            isClauseLibraryTab={true}
            openDocxEditor={switchToDocxEdit}
            isDocxOpen={canEdit}
          />
        </DrawerComponent>
      )}
      {showDraftLockedAlert && (
        <DraftViewAlert
          id={id}
          isOpen={showDraftLockedAlert}
          onClose={() => setShowDraftLockedAlert(false)}
          data={draftData?.locked_by}
        />
      )}
      {templateError && (
        <TemplateErrorAlert
          isOpen={templateError != null}
          onClose={() => setTemplateError(null)}
          templateError={templateError}
        />
      )}
      {showPopup && approvalData?.length > 0 && (
        <ReminderDraftCreatedModal
          open={showPopup}
          onClose={() => setShowPopup(false)}
          approvalData={approvalData}
          updateDraftData={updateDraftData}
          draftData={draftData}
          loadingUpdateItem={updatingDraft}
        />
      )}
    </React.Fragment>
  );
};

export default ViewDraft;
