import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ApprovalCard from './ApprovalCard';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { getDraftApprovals } from '../../../Services/Draft';
import { fetchExtractedData } from '../../../Services/Insights';

interface IProps {
  draftData: any;
}

const ApprovalTab: React.FC<IProps> = ({ draftData }) => {
  const { data: extractedData, isLoading } = useQuery({
    queryKey: ['clause_term', draftData?.id],
    queryFn: () => fetchExtractedData(draftData?.id),
    enabled: !!(
      draftData?.id &&
      (draftData?.status?.toLowerCase().includes('internal draft created') ||
        draftData?.status
          ?.toLowerCase()
          .includes('counter party draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('internal draft created') ||
        draftData?.drop_status
          ?.toLowerCase()
          .includes('counter party draft created'))
    ),
  });

  const { data: approvalData, isLoading: approvalLoading } = useQuery({
    queryKey: [
      'draft_approvals',
      draftData?.id,
      draftData?.version,
      draftData?.pre_signatories,
    ],
    queryFn: () => {
      const approval_type = draftData?.pre_signatories?.length
        ? 'pre_signatory_approvers'
        : 'deviation_approvers';
      const type =
        draftData?.version === 0 ? 'requisition_approvers' : approval_type;
      return getDraftApprovals(draftData?.id, type);
    },
    select: (response: any) => response?.results,
    enabled: !!draftData?.id,
  });

  const { data: templateApprovalData, isFetching: templateLoading } = useQuery({
    queryKey: [
      'template_approvals_data',
      draftData?.draftID,
      draftData?.version,
    ],
    queryFn: () => {
      const type =
        draftData?.version === 0
          ? 'requisition_approvers'
          : 'deviation_approvers';

      return getDraftApprovals(draftData?.draftID, type);
    },
    select: (response: any) => response?.results,
    enabled: !!(
      draftData?.createFrom === 'template' && draftData?.version === 1
    ),
  });

  if (approvalLoading || isLoading || templateLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }

  return (
    <Stack spacing={1}>
      {approvalData?.length > 0 && (
        <>
          {approvalData?.map((approvals: any) => (
            <ApprovalCard
              key={approvals?.id}
              approvals={approvals}
              extractedData={extractedData}
              draftData={draftData}
            />
          ))}
        </>
      )}
      {templateApprovalData?.length > 0 && (
        <>
          {templateApprovalData?.map((approvals: any) => (
            <ApprovalCard
              key={approvals?.id}
              approvals={approvals}
              extractedData={extractedData}
              draftData={draftData}
            />
          ))}
        </>
      )}

      {approvalData?.length === 0 && templateApprovalData?.length === 0 && (
        <Typography p="10px">No pending approvals</Typography>
      )}
    </Stack>
  );
};

export default ApprovalTab;
