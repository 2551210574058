import React, { useCallback } from 'react';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import { editReminder } from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleClose: VoidFunction;
  reminderData: any;
}
const SendReminderNowModal: React.FC<Props> = (props) => {
  const { open, onClose, reminderData, handleClose } = props;
  const { handleSubmit, control } = useForm();

  const { mutate: update_reminder, isPending: loadingEditReminder } =
    useMutation({
      mutationKey: ['send_reminder_now'],
      mutationFn: editReminder,
      onSuccess: () => {
        enqueueSnackbar('Reminder updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        handleClose();
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Failed to update Reminder!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const onSubmit = useCallback(
    (data: any) => {
      const sendNowReminderPayload = {
        id: reminderData?.id,
        body: {
          send_reminder: true,
          comments: data?.note,
        },
      };

      update_reminder(sendNowReminderPayload);
    },
    [reminderData, update_reminder]
  );

  return (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="Send a reminder"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} pt="20px" sx={{ width: '500px' }}>
          <Typography variant="subtitle2" color="#000">
            Are you sure you want to send a reminder to the following:
          </Typography>

          <Stack
            direction="row"
            justify-content="start"
            align-item="center"
            spacing={1}
          >
            {reminderData?.users?.map((user: any, index: number) => (
              <Chip
                key={index}
                sx={{
                  background: '#FFF7FA',
                  borderRadius: '8px',
                }}
                icon={
                  <PersonOutlineIcon
                    sx={{ color: '#88305F', fontSize: '14px' }}
                  />
                }
                label={
                  <Typography variant="subtitle2" fontWeight="500">
                    {user?.first_name}
                  </Typography>
                }
                size="small"
              />
            ))}
            {reminderData?.external_user?.map((user: any, index: number) => (
              <Chip
                key={index}
                sx={{
                  background: '#FFF7FA',
                  borderRadius: '8px',
                }}
                icon={
                  <PersonOutlineIcon
                    sx={{ fill: '#88305F', fontSize: '14px' }}
                  />
                }
                label={
                  <Typography variant="subtitle2" fontWeight="500">
                    {user?.name}
                  </Typography>
                }
                size="small"
              />
            ))}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2" fontWeight="500">
              (Optional) Note for approver:
            </Typography>
            <ControlledTextField
              name="note"
              control={control}
              label="Note"
              fullWidth
            />
          </Stack>
          <Stack alignItems="center" direction="row" marginTop="20px">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loadingEditReminder}
            >
              Yes, send reminder
            </LoadingButton>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default SendReminderNowModal;
