import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { getPara } from '../../../DocumentView/Component/ClauseComponent/utils/ClauseTypeUtils';
import ReminderFormStylus from '../../../DocumentView/Component/Reminders/ReminderFormStylus';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import {
  editReminder,
  remindersDraft,
} from '../../../Services/DocumentLibrary';
import {
  addDraftApprovalBulkComments,
  addDraftApprovalComments,
  deleteApprovalComment,
  updateDeviationApprovals,
} from '../../../Services/Draft';
import { fetchReminderById } from '../../../Services/Reminder';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import { draftStatus } from '../../State/DraftState';
import {
  automaticallyDroppedMsg,
  droppedMsg,
  handleSeeMoreAndLess,
} from '../Helper';
import { restrictionStatus } from '../StaticData';

interface IProps {
  extractedData: any;
  approvals?: any;
  instance?: any;
  draftData: any;
}

const NameWithAvatar = ({
  firstName,
  lastName,
  content,
}: {
  firstName: string;
  lastName: string;
  content?: string;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <NameAvatar
        firstName={firstName}
        lastName={lastName}
        avatarStyle={{ width: '40px', height: '40px' }}
      />
      <Typography variant="subtitle1" sx={{ lineHeight: '1.4' }}>
        {`${firstName} ${lastName}`} {content}
      </Typography>
    </Stack>
  );
};

const ApprovalCard = React.forwardRef<IProps, any>((props) => {
  const { approvals, extractedData, draftData } = props;

  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({});
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [approvalData, setApprovalData] = useState<any>({});
  const [reminderID, setReminderID] = useState<string>('');
  const [isCommentHovered, setIsCommentHovered] = useState<{
    [key: string]: boolean;
  }>({});

  const { handleSubmit, control, reset } = useForm();
  const [showFullText, setShowFullText] = useState<any>({
    id: '',
    show: false,
  });
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<any>('');
  const [showCommentsInput, setShowCommentsInput] = useState<any>('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const queryClient = useQueryClient();

  const { user_data, user_id } = useUserData();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('reminder_type');
  const getReminderId = queryParams.get('reminderId');

  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const isOwner = useMemo(
    () =>
      draftData?.owners.some((owner: { id: string }) => owner?.id === user_id),
    [draftData?.owners, user_id]
  );

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],

    mutationFn: (payload: any) => {
      const currentApprover = approvals?.approvers.filter(
        (data: any) => data.approvers?.id === user_id
      );
      const id = currentApprover[0]?.reminders?.[0];
      const updatePayload = { id, body: payload };
      return editReminder(updatePayload);
    },

    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (
      (queryParamsTypeValue === 'approval' ||
        queryParamsTypeValue === 'deviation' ||
        queryParamsTypeValue === 'custom_deviation' ||
        queryParamsTypeValue === 'pre_signatory_approval') &&
      getReminderId
    ) {
      if (approvals?.deviation !== null) {
        approvals?.approvers?.map((approver: any) => {
          if (approver?.reminders?.[0] === getReminderId) {
            setOpenForm(true);
            setApprovalData(approver);
            setReminderID(getReminderId);
          }
        });
      } else if (approvals?.deviation === null) {
        approvals?.approvers?.map((approver: any) => {
          setOpenForm(true);
          setApprovalData(approver);
          setReminderID(getReminderId);
        });
      }
    }
  }, [queryParamsTypeValue, getReminderId, approvals]);

  const { data: reminderById } = useQuery({
    queryKey: ['get_reminder_by_id', reminderID],
    queryFn: async () => await fetchReminderById(reminderID),
    enabled: !!reminderID,
  });

  const { mutate: addCommentMutation } = useMutation({
    mutationFn: addDraftApprovalComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_approvals_data'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_Kdp_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_custom_deviations'],
      });
      reset();
      setIsButtonClicked(false);
    },
  });

  const { mutate: addBulkCommentMutation } = useMutation({
    mutationKey: ['draft_approvals_bulk_comments'],
    mutationFn: addDraftApprovalBulkComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_approvals_data'],
      });
    },
  });

  const { mutate: delete_approval_comment } = useMutation({
    mutationKey: ['delete-approval-comment'],
    mutationFn: deleteApprovalComment,
    onSuccess: () => {
      enqueueSnackbar('Comment deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_approvals_data'],
      });
      setIsButtonClicked(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Comment!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleCommentHover = (commentId: string, isHovered: boolean) => {
    setIsCommentHovered((prevState) => ({
      ...prevState,
      [commentId]: isHovered,
    }));
  };

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [approvals]);

  const handleCommentsClick = useCallback(
    (clickedApprover: any) => {
      if (isButtonClicked || showCommentsInput) {
        setIsButtonClicked(false);
        setShowCommentsInput(false);
      }
      const bulkCommentIds = clickedApprover?.comments
        ?.filter((comment: any) => !comment.is_read)
        ?.map((comment: any) => comment?.id);

      const hasUnreadCommentsByOthers = clickedApprover?.comments?.some(
        (comment: any) =>
          comment?.created_by?.id !== user_id &&
          !comment?.is_read &&
          clickedApprover?.approvers?.id === comment?.commented_for
      );
      const canCallApiAsOwner = !isOwner && hasUnreadCommentsByOthers;
      const canCallApiAsNonOwner =
        isOwner &&
        bulkCommentIds?.length > 0 &&
        clickedApprover?.comments?.some(
          (comment: any) =>
            !comment?.is_read &&
            comment?.created_by?.id !== clickedApprover?.created_by?.id
        );

      if (
        bulkCommentIds?.length > 0 &&
        (canCallApiAsOwner || canCallApiAsNonOwner)
      ) {
        const payload = {
          ids: bulkCommentIds,
          is_read: true,
        };
        addBulkCommentMutation(payload);
      }

      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }
    },
    [
      addBulkCommentMutation,
      isOwner,
      user_id,
      isButtonClicked,
      showCommentsInput,
    ]
  );

  const { mutate: updateApprovals, isPending: updateLoading } = useMutation({
    mutationKey: ['update_approval'],
    mutationFn: (payload: any) => {
      const currentApprover = approvals?.approvers.filter(
        (data: any) => data.approvers.id === user_id
      );
      const id = currentApprover[0].id;
      return updateDeviationApprovals(id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_approvals_data'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_Kdp_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_custom_deviations'],
      });
    },
  });

  const approveButton: { disabled: boolean; text: string } =
    React.useMemo(() => {
      const approval = approvals?.approvers?.filter(
        (data: any) => data.approvers.id === user_id
      );
      const priority = approval[0]?.priority;
      let disabled = false;
      let text = 'Approve';
      if (approvals?.approval_sequence === 'all_in_sequence') {
        if (priority === 1) return { disabled: false, text };
        approvals?.approvers?.map((data: any) => {
          if (data.priority < priority) {
            if (data.status === 'approval_pending') {
              disabled = true;
              text = `Approval pending from ${data?.approvers?.first_name} ${data?.approvers?.last_name}`;
            }
          }
          return {
            disabled,
            text,
          };
        });
      }
      return { disabled, text };
    }, [approvals, user_id]);

  const handleToggle = (approverId: string, deviationId: string) => {
    setShowFullText(
      (prevShowFullText: { [key: string]: { id: string; show: boolean } }) => ({
        ...prevShowFullText,
        [approverId]: {
          id: deviationId,
          show: !prevShowFullText[approverId]?.show || false,
        },
      })
    );
  };

  const handleOpenModal = useCallback(
    (clickedApprover: any) => {
      if (clickedApprover?.reminders?.[0]) {
        setReminderID(clickedApprover?.reminders?.[0]);
      }
      setOpenConfirmationModal(true);
    },
    [setReminderID, setOpenConfirmationModal]
  );

  const approveDraft = React.useCallback(() => {
    updateApprovals({ status: 'approved' });
    if (reminderID) {
      update_reminder({ status: 'Completed' });
    }
  }, [reminderID, updateApprovals, update_reminder]);

  const handleCloseForm = () => {
    if (
      queryParamsTypeValue === 'approval' ||
      queryParamsTypeValue === 'deviation' ||
      queryParamsTypeValue === 'custom_deviation' ||
      queryParamsTypeValue === 'pre_signatory_approval'
    ) {
      navigate(pathname);
    }
    setOpenForm(false);
    setReminderID('');
  };

  const toggleInput = useCallback(
    (approverId: string) => {
      if (isButtonClicked) {
        setIsButtonClicked(false);
      }
      setShowInput((prevState) => {
        if (showCommentsInput) {
          return {
            ...prevState,
            [approverId]: false,
          };
        }
        return prevState;
      });
      setShowInput((prevState) => {
        if (showCommentsInput !== approverId) {
          return {
            ...prevState,
            [approverId]: !prevState[approverId] || false,
          };
        }
        return prevState;
      });
      setShowCommentsInput('');
    },
    [setShowInput, showCommentsInput, isButtonClicked]
  );

  const isDraftDropped = useMemo(
    () => restrictionStatus?.includes(draftData?.status),
    [draftData?.status]
  );

  const handleButtonClick = (users: any) => {
    const approverId = users?.[0];
    const filteredApprovers =
      approvals?.approvers?.filter(
        (approver: any) => approver?.approvers?.id === approverId
      ) || [];

    const filteredApproversId =
      approvals?.approvers
        ?.filter((approver: any) => approver?.approvers?.id === approverId)
        .map((approver: any) => approver?.id) || [];

    const isComments = filteredApprovers?.some(
      (approver: any) => approver?.comments?.length > 0
    );
    handleCloseForm();
    if (isComments) {
      setIsButtonClicked(true);
      handleCommentsClick(filteredApprovers[0]);
      setAccordionExpanded(approverId);
    } else {
      setShowCommentsInput(filteredApproversId[0]);
    }
  };

  const onSubmit = useCallback(
    (data: any, approverId: string) => {
      const commentedForId = approvals?.approvers.find(
        (approver: any) => approver.id === approverId
      )?.approvers?.id;

      const payload = {
        approval: approvals?.id,
        comment: data?.[`comment-${approverId}`],
        commented_for: commentedForId || '',
      };
      toggleInput(approverId);
      addCommentMutation(payload);
    },
    [approvals, addCommentMutation, toggleInput]
  );

  const { mutate: reminders_Draft } = useMutation({
    mutationKey: ['reminders_draft'],
    mutationFn: remindersDraft,
    onSuccess: (response: any) => {
      setReminderID(response?.reminder_id);
    },
  });

  const handleOpenForm = useCallback(
    (clickedApprover: any) => {
      if (clickedApprover?.reminders?.[0]) {
        setReminderID(clickedApprover?.reminders?.[0]);
      } else if (draftData?.version === 0) {
        const payload = {
          approval_type: 'approval',
          users: clickedApprover?.approvers?.id,
          draft: draftData?.id,
        };
        reminders_Draft(payload);
      }
      setOpenForm(true);
    },
    [draftData?.id, draftData?.version, reminders_Draft]
  );

  const isCustomIssueOwner = useMemo(() => {
    if (approvals?.deviation?.deviation_type === 'custom') {
      const isCustomCreateBY = approvals?.created_by?.id === user_id;
      return isCustomCreateBY;
    }
    return false;
  }, [approvals, user_id]);

  const handleConfirmation = useCallback(() => {
    setOpenConfirmationModal(false);
    approveDraft();
  }, [approveDraft]);

  const currentApprovalId = useMemo(() => {
    return approvals?.approvers?.find(
      (value: any) =>
        value?.id === showCommentsInput ||
        value?.approvers?.id === accordionExpanded
    )?.id;
  }, [approvals, showCommentsInput, accordionExpanded]);

  //  On re-routing scroll to the corresponding approval card
  useEffect(() => {
    const element = refs.current[currentApprovalId];
    if (currentApprovalId && element) {
      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 300);
    }
  }, [isButtonClicked, currentApprovalId]);

  const approvalType = useMemo(
    () => approvals?.approval_sequence?.toLowerCase(),
    [approvals?.approval_sequence]
  );

  const approversActiveStatus = useMemo(() => {
    const approvers = approvals?.approvers || [];
    const allInactive = approvers?.every(
      (approver: any) => !approver?.approvers?.is_active
    );
    const oneInactive = approvers?.some(
      (approver: any) =>
        !approver?.approvers?.is_active &&
        (approvalType === 'all' || approvalType === 'all_in_sequence')
    );
    const showCustomMessage =
      allInactive &&
      approvals?.approval_type === 'deviation_approvers' &&
      approvals?.deviation?.deviation_type !== 'kdp';

    return { allInactive, oneInactive, showCustomMessage };
  }, [
    approvals?.approvers,
    approvals?.approval_type,
    approvals?.deviation?.deviation_type,
    approvalType,
  ]);

  const approverCount = approvals?.approvers?.length;
  const inactiveApproversCount = approvals?.approvers?.filter(
    (approver: any) => !approver?.approvers?.is_active
  ).length;

  const tooltipTitles = useMemo(() => {
    if (!approvals?.approvers) return [];
    return approvals?.approvers?.map((approver: any) => {
      const isActive = approver?.approvers?.is_active;
      const approverName =
        `${approver?.approvers?.first_name} ${approver?.approvers?.last_name}`.trim();

      if (approversActiveStatus.allInactive) {
        switch (approvals?.approval_type) {
          case 'requisition_approvers':
            return 'All approvers are inactive. Request you to alter the policy and initiate the draft request.';
          case 'deviation_approvers':
            return 'Policy approvers are inactive. Request you to change org policy and upload a new version.';
          case 'pre_signatory_approvers':
            return 'All pre-signatory approvers are inactive. Request you to change org policy and upload a new version.';
          default:
            return '';
        }
      }

      if (!isActive) {
        switch (approvals?.approval_type) {
          case 'requisition_approvers':
            return ['all', 'all_in_sequence'].includes(approvalType)
              ? `${approverName} is now inactive and the approval can’t be recorded. Request you to alter the policy and initiate the draft request.`
              : `${approverName} is now inactive.`;
          case 'deviation_approvers': {
            const { deviation_type } = approvals.deviation || {};
            if (deviation_type === 'custom') {
              if (inactiveApproversCount >= 2) {
                return `Out of ${approverCount} required custom approvers, ${inactiveApproversCount} are inactive. To proceed request you to withdraw escalation and add a new custom issue.`;
              }
              return ['all', 'all_in_sequence'].includes(approvalType) ||
                approverCount === 1
                ? `${approverName} is now inactive and the approval can’t be recorded. Request you to withdraw escalation and add a new custom issue.`
                : `${approverName} is now inactive.`;
            } else if (deviation_type === 'kdp') {
              return ['all', 'all_in_sequence'].includes(approvalType) ||
                approverCount === 1
                ? `${approverName} is now inactive and the approval can’t be recorded. Request you to change org policy and upload a new version.`
                : `${approverName} is now inactive.`;
            }
            return `${approverName} is now inactive.`;
          }
          case 'pre_signatory_approvers':
            return ['all', 'all_in_sequence'].includes(approvalType)
              ? `${approverName} is now inactive and the approval can’t be recorded. Request you to alter the policy and initiate the draft request.`
              : `${approverName} is now inactive.`;
          default:
            return '';
        }
      }
      return '';
    });
  }, [
    approvals?.approvers,
    approversActiveStatus.allInactive,
    approvalType,
    approvals?.approval_type,
    approvals?.deviation?.deviation_type,
    inactiveApproversCount,
    approverCount,
  ]);

  const approverMessage = useMemo(() => {
    if (approversActiveStatus.allInactive) {
      if (approvals?.approval_type === 'requisition_approvers') {
        return 'All approvers are inactive and the approval can’t be recorded. Request you to alter the policy and initiate the draft request.';
      }
      if (approvals?.approval_type === 'pre_signatory_approvers') {
        return 'All pre-signatory approvers are inactive. To proceed, request you to change org policy and upload a new version.';
      }
      return approversActiveStatus.showCustomMessage
        ? 'Custom approvers are inactive. Request you to withdraw escalation.'
        : 'Approvers are inactive. To proceed, request you to change org policy and upload a new version.';
    }

    if (approversActiveStatus.oneInactive) {
      if (approvals?.approval_type === 'requisition_approvers') {
        return 'One of the approvers is inactive and the approval can’t be recorded. Request you to alter the policy and initiate the draft request.';
      }
      if (approvals?.approval_type === 'pre_signatory_approver') {
        return 'One of the pre-signatory approvers is inactive and the approval can’t be recorded. Request you to alter the policy and initiate the draft request.';
      }
      if (
        approvals?.approval_type === 'deviation_approvers' &&
        approvals?.deviation?.deviation_type !== 'kdp' &&
        inactiveApproversCount >= 2
      ) {
        return `Out of ${approverCount} required custom approvers, ${inactiveApproversCount} are inactive. Request you to withdraw escalation.`;
      }
      return 'One of the policy approvers is inactive and the approval can’t be recorded. Request you to change org policy and upload a new version.';
    }

    return null;
  }, [
    approversActiveStatus,
    approvals?.approval_type,
    approvals?.deviation?.deviation_type,
    inactiveApproversCount,
    approverCount,
  ]);

  return (
    <React.Fragment>
      <Stack spacing={1.5} sx={{ padding: '8px' }}>
        {approverMessage && (
          <Typography variant="caption">{approverMessage}</Typography>
        )}
        {approvals?.approvers?.map((approver: any, index: number) => {
          return (
            <Tooltip key={approver?.id} title={tooltipTitles[index] || ''}>
              <Stack
                key={approver?.id}
                ref={(el) => {
                  refs.current[approver.id] = el;
                }}
                className={`according-class ${!approver?.approvers?.is_active ? 'reminderCardDeleted' : ''}`}
                sx={{
                  background: '#88305F1F',
                  p: '16px',
                  borderRadius: '18px',
                }}
              >
                <Stack
                  spacing={1.5}
                  sx={{
                    pointerEvents: !approver?.approvers?.is_active
                      ? 'none'
                      : '',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <NameWithAvatar
                        firstName={approver?.approvers?.first_name}
                        lastName={approver?.approvers?.last_name}
                      />
                      {!approver?.approvers?.is_active && (
                        <CustomChip
                          sx={{
                            background: '#f3eaef',
                            padding: '10px 4px',
                            borderRadius: '5px',
                            '& .MuiChip-label': {
                              paddingRight: '0',
                              paddingLeft: '4px',
                              fontSize: '12px',
                            },
                          }}
                          label="Inactive"
                        />
                      )}
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                      {approver?.status === 'approval_pending' ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="caption" letterSpacing="0.5px">
                            Approval pending
                          </Typography>
                          <AccessTimeOutlinedIcon sx={{ fontSize: '18px' }} />
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="caption" letterSpacing="0.5px">
                            Approved on
                          </Typography>
                          <Typography
                            variant="caption"
                            letterSpacing="0.5px"
                            marginLeft="4px"
                          >
                            {approver?.modified_on &&
                              dayjs(approver.modified_on).format('DD/MM/YYYY')}
                          </Typography>
                          <CheckCircleOutlinedIcon sx={{ fontSize: '18px' }} />
                        </Stack>
                      )}
                      {approver?.status === 'approval_pending' &&
                        approver?.approvers?.id === user_id && (
                          <Tooltip
                            title={
                              draftData?.status === draftStatus?.Draft_Dropped
                                ? droppedMsg
                                : draftData?.status ===
                                    draftStatus?.Automatically_Dropped
                                  ? automaticallyDroppedMsg
                                  : ''
                            }
                            arrow
                            disableHoverListener={!isDraftDropped}
                          >
                            <Stack>
                              <LoadingButton
                                variant="outlined"
                                size="small"
                                loading={updateLoading}
                                sx={{
                                  padding: '4px 12px',
                                  whiteSpace: 'nowrap',
                                  color: !approver?.approvers?.is_active
                                    ? 'grey'
                                    : '',
                                }}
                                onClick={() => {
                                  handleOpenModal(approver);
                                }}
                                disabled={
                                  approveButton?.disabled || isDraftDropped
                                }
                                startIcon={<DoneIcon />}
                              >
                                {approveButton.text}
                              </LoadingButton>
                            </Stack>
                          </Tooltip>
                        )}
                    </Stack>
                  </Stack>
                  {(approvals?.deviation?.custom_datapoint?.length > 0 ||
                    approvals?.deviation?.kdp_datapoint?.length > 0) && (
                    <Stack spacing={1}>
                      <Typography variant="body2" fontWeight={600}>
                        Approval required on the following text from the draft:
                      </Typography>
                      {approvals?.deviation?.custom_datapoint?.map(
                        (item: any, index: number) => (
                          <Stack
                            spacing={1}
                            key={index}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="start"
                            sx={{
                              padding: '10px',
                              background: '#FFF7FA',
                              borderRadius: '10px',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography fontSize="13px">
                              {showFullText[approver.id]?.id ===
                                approvals?.deviation?.id &&
                              showFullText[approver.id]?.show
                                ? item?.selectedValue
                                : handleSeeMoreAndLess(item?.selectedValue)}
                              {item?.selectedValue?.split(/\s+/)?.length >
                                20 && (
                                <span
                                  onClick={() =>
                                    handleToggle(
                                      approver.id,
                                      approvals?.deviation?.id
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {showFullText[approver.id]?.id ===
                                    approvals?.deviation?.id &&
                                  showFullText[approver.id]?.show
                                    ? ' See less'
                                    : ' See more'}
                                </span>
                              )}
                            </Typography>
                          </Stack>
                        )
                      )}
                      {approvals?.deviation?.kdp_datapoint?.map(
                        (item: any, index: number) => (
                          <Stack
                            key={index}
                            spacing={1}
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                              padding: '10px',
                              background: '#FFF7FA',
                              borderRadius: '10px',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography fontSize="13px">
                              {showFullText[approver.id]?.id ===
                                approvals?.deviation?.id &&
                              showFullText[approver.id]?.show
                                ? getPara(
                                    extractedData?.raw_content,
                                    item.para_id
                                  )
                                : handleSeeMoreAndLess(
                                    item?.selectedValue ||
                                      getPara(
                                        extractedData?.raw_content,
                                        item.para_id
                                      )
                                  )}
                              {getPara(
                                extractedData?.raw_content,
                                item.para_id
                              )?.split(/\s+/)?.length > 20 && (
                                <span
                                  onClick={() =>
                                    handleToggle(
                                      approver.id,
                                      approvals?.deviation?.id
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    color: '#88305F',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {showFullText[approver.id]?.id ===
                                    approvals?.deviation?.id &&
                                  showFullText[approver.id]?.show
                                    ? ' See less'
                                    : 'See more'}
                                </span>
                              )}
                            </Typography>
                          </Stack>
                        )
                      )}
                    </Stack>
                  )}
                  {(isOwner ||
                    approver?.approvers?.id === user_id ||
                    isCustomIssueOwner) && (
                    <Stack spacing={2}>
                      {approver?.comments && approver?.comments.length > 0 && (
                        <Accordion
                          sx={{
                            background: 'unset',
                            boxShadow: 'none',
                          }}
                          expanded={
                            accordionExpanded === approver?.approvers?.id
                          }
                        >
                          <AccordionSummary
                            sx={{
                              padding: '0 12px',
                              backgroundColor: !approver?.approvers?.is_active
                                ? '#e3d6d9'
                                : '#fbe3e9',
                              borderRadius:
                                accordionExpanded === approver?.approvers?.id
                                  ? '10px 10px 0 0'
                                  : '10px ',
                            }}
                            onClick={() => {
                              handleCommentsClick(approver);
                              setAccordionExpanded((prev: any) =>
                                prev === approver?.approvers?.id
                                  ? null
                                  : approver?.approvers?.id
                              );
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: !approver?.approvers?.is_active
                                    ? 'grey'
                                    : '#6D264C',
                                }}
                              />
                            }
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1.5}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                textTransform="uppercase"
                                color={
                                  !approver?.approvers?.is_active
                                    ? 'grey'
                                    : '#6D264C'
                                }
                              >
                                Comments
                              </Typography>

                              {approver?.unread_comment_count > 0 &&
                                approver?.comments?.some(
                                  (comment: any) =>
                                    !comment?.is_read &&
                                    comment?.created_by?.id !== user_id &&
                                    approver?.approvers?.id !==
                                      approvals?.created_by?.id
                                ) && (
                                  <Typography
                                    variant="caption"
                                    fontWeight={600}
                                    color="#A0597F"
                                    border="2px solid"
                                    borderRadius="10px"
                                    padding="2px 6px"
                                    sx={{ borderStyle: 'dashed' }}
                                  >
                                    {approver?.unread_comment_count > 1
                                      ? `${approver.unread_comment_count} New Messages`
                                      : `${approver.unread_comment_count} New Message`}
                                  </Typography>
                                )}
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: '0 12px 16px',
                              backgroundColor: '#fbe3e9',
                              borderRadius: '0 0 10px 10px',
                            }}
                          >
                            <Stack spacing={2}>
                              <Scrollable
                                maxHeight={250}
                                ref={commentsContainerRef}
                              >
                                <Stack spacing={1}>
                                  {approver?.comments
                                    ?.slice()
                                    .reverse()
                                    .map((comment: any) => (
                                      <Stack
                                        key={comment}
                                        spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        onMouseEnter={() =>
                                          handleCommentHover(comment.id, true)
                                        }
                                        onMouseLeave={() =>
                                          handleCommentHover(comment.id, false)
                                        }
                                      >
                                        {comment?.created_by?.id !==
                                          user_id && (
                                          <NameAvatar
                                            firstName={
                                              comment?.created_by?.first_name
                                            }
                                            lastName={
                                              comment?.created_by?.last_name
                                            }
                                            avatarStyle={{
                                              width: '40px',
                                              height: '40px',
                                            }}
                                          />
                                        )}
                                        <TextField
                                          key={comment.id}
                                          value={comment?.comment}
                                          disabled
                                          fullWidth
                                        />
                                        {comment?.created_by?.id ===
                                          user_id && (
                                          <NameAvatar
                                            firstName={
                                              comment?.created_by?.first_name
                                            }
                                            lastName={
                                              comment?.created_by?.last_name
                                            }
                                            avatarStyle={{
                                              width: '40px',
                                              height: '40px',
                                            }}
                                          />
                                        )}
                                        {isCommentHovered[comment.id] &&
                                          comment?.created_by?.id === user_id &&
                                          !isDraftDropped && (
                                            <Tooltip title="Delete Comment">
                                              <DeleteOutlineIcon
                                                sx={{
                                                  cursor: 'pointer',
                                                  fontSize: '20px',
                                                  color: '#6D264C',
                                                }}
                                                onClick={() =>
                                                  delete_approval_comment(
                                                    comment?.id
                                                  )
                                                }
                                              />
                                            </Tooltip>
                                          )}
                                      </Stack>
                                    ))}
                                </Stack>
                              </Scrollable>

                              {approver?.comments?.length > 0 &&
                                (approver?.approvers?.id === user_id ||
                                  isOwner) && (
                                  <Stack
                                    component="form"
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    onSubmit={handleSubmit((data) =>
                                      onSubmit(data, approver?.id)
                                    )}
                                  >
                                    <ControlledTextField
                                      disabled={isDraftDropped}
                                      name={`comment-${approver?.id}`}
                                      control={control}
                                      fullWidth
                                      className={
                                        accordionExpanded ===
                                          approver?.approvers?.id &&
                                        !openForm &&
                                        isButtonClicked
                                          ? 'blink-border-animation'
                                          : ''
                                      }
                                      sx={{
                                        background: '#FFF7FA',
                                        borderRadius: '6px',
                                        border:
                                          accordionExpanded ===
                                            approver?.approvers?.id &&
                                          !openForm &&
                                          isButtonClicked
                                            ? '2px solid transparent'
                                            : '',
                                      }}
                                    />
                                    <NameAvatar
                                      firstName={user_data.first_name}
                                      lastName={user_data.last_name}
                                      avatarStyle={{
                                        width: '40px',
                                        height: '40px',
                                      }}
                                    />
                                  </Stack>
                                )}

                              {approver?.comments?.length > 0 &&
                                (approver?.approvers?.id === user_id ||
                                  isOwner) && (
                                  <Link
                                    fontSize="14px"
                                    display="flex"
                                    alignItems="center"
                                    whiteSpace="nowrap"
                                    sx={{
                                      textDecoration: 'unset',
                                    }}
                                  >
                                    <ChatBubbleOutlineIcon
                                      sx={{
                                        mr: '6px',
                                        fontSize: '14px',
                                        marginTop: '0.4rem',
                                      }}
                                    />
                                    Add/Reply Comment
                                  </Link>
                                )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {approver?.comments?.length === 0 &&
                        (showCommentsInput === approver?.id ||
                          showInput[approver?.id]) && (
                          <Stack
                            component="form"
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            onSubmit={handleSubmit((data) =>
                              onSubmit(data, approver?.id)
                            )}
                          >
                            <ControlledTextField
                              name={`comment-${approver?.id}`}
                              control={control}
                              fullWidth
                              className={
                                showCommentsInput === approver?.id && !openForm
                                  ? 'blink-border-animation'
                                  : ''
                              }
                              sx={{
                                background: '#FFF7FA',
                                borderRadius: '6px',
                                border:
                                  showCommentsInput === approver?.id &&
                                  !openForm
                                    ? '2px solid transparent'
                                    : '',
                              }}
                            />
                            <NameAvatar
                              firstName={user_data.first_name}
                              lastName={user_data.last_name}
                              avatarStyle={{ width: '40px', height: '40px' }}
                            />
                          </Stack>
                        )}
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    {approver?.comments?.length === 0 &&
                      (isOwner ||
                        approver?.approvers?.id === user_id ||
                        isCustomIssueOwner) &&
                      !isDraftDropped && (
                        <Link
                          onClick={() => toggleInput(approver?.id)}
                          fontSize="14px"
                          display="flex"
                          alignItems="center"
                          whiteSpace="nowrap"
                          sx={{
                            cursor: 'pointer',
                            textDecoration: 'unset',
                            color: !approver?.approvers?.is_active
                              ? 'grey'
                              : '',
                          }}
                        >
                          <ChatBubbleOutlineIcon
                            sx={{
                              mr: '6px',
                              fontSize: '14px',
                              marginTop: '0.4rem',
                            }}
                          />
                          Add/Reply Comment
                        </Link>
                      )}

                    {approver?.status !== 'approval_pending' ||
                      ((isOwner || isCustomIssueOwner) && !isDraftDropped && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            padding: '8px 22px',
                            whiteSpace: 'nowrap',
                            ml: 'auto',
                            color: !approver?.approvers?.is_active
                              ? 'grey'
                              : '',
                          }}
                          onClick={() => {
                            setApprovalData(approver);
                            handleOpenForm(approver);
                          }}
                          startIcon={<EventIcon />}
                        >
                          Approval{' '}
                          {approvals?.deviation ? 'requested' : 'reminder'}
                        </Button>
                      ))}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      variant="caption"
                      color={
                        !approver?.approvers?.is_active ? 'grey' : '#A0597F'
                      }
                      fontWeight={600}
                    >
                      Created By :
                    </Typography>
                    <Tooltip
                      title={
                        !approvals?.created_by?.is_active
                          ? `${approvals?.created_by?.first_name} ${approvals?.created_by?.last_name} is Inactive.`
                          : ''
                      }
                    >
                      <Stack>
                        <NameAvatar
                          firstName={approvals?.created_by?.first_name}
                          lastName={approvals?.created_by?.last_name}
                          avatarStyle={{
                            width: '24px',
                            height: '24px',
                            fontSize: '10px',
                            lineHeight: '16px',
                            backgroundColor: !approvals?.created_by?.is_active
                              ? 'rgba(0, 0, 0, 0.15)'
                              : '#A0597F',
                          }}
                          disableTooltip={!approvals?.created_by?.is_active}
                        />
                      </Stack>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Tooltip>
          );
        })}
      </Stack>
      <CustomModal
        open={openForm}
        handleClose={handleCloseForm}
        title={
          draftData?.approval_type === 'contract_condition' ||
          (draftData?.approval_type === 'pre_signatory_approval' &&
            !draftData?.pre_signatories)
            ? `${draftData?.contractName} - Conditional approval`
            : draftData?.approval_type === 'pre_signatory_approval' &&
                draftData?.pre_signatories
              ? 'Pre Signatory Approval'
              : draftData?.approval_type === 'contract_request'
                ? `${draftData?.contractName} - Requisition Approval`
                : reminderById?.name || draftData?.contractName
        }
        maxWidth="md"
      >
        <ReminderFormStylus
          onClose={handleCloseForm}
          approvalData={approvalData}
          reminderData={reminderById}
          approvals={approvals}
          draftData={draftData}
          reminderID={reminderID}
          queryParamsTypeValue={queryParamsTypeValue}
          handleButtonClick={handleButtonClick}
          setIsButtonClicked={setIsButtonClicked}
          isButtonClicked={isButtonClicked}
        />
      </CustomModal>
      {openConfirmationModal && (
        <ReusableConfirmationModal
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          title="Confirm Approval"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Approve"
          onConfirm={handleConfirmation}
        >
          <Stack spacing={2}>
            <Typography>
              This action is not reversible, are you sure you want to approve?
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
});

ApprovalCard.displayName = 'ApprovalCard';

export default ApprovalCard;
