import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TagIcon from '@mui/icons-material/Tag';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchContractType } from '../../../Services/Approval';
import {
  addProject,
  editReplaceTags,
  fetchContractById,
  fetchGroups,
  fetchProjects,
} from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  selectedIds: GridRowId[];
  replace: boolean;
  title: string;
}

const ReplaceAndEditTag: React.FC<Props> = ({
  open,
  onClose,
  selectedIds,
  replace,
  title,
}) => {
  const [isAddNewProject, setIsAddNewProject] = useState<boolean>(false);
  const { handleSubmit, control, watch, resetField, setValue, reset } =
    useForm();
  const queryClient = useQueryClient();

  const project_name = watch('projectName') || '';

  const handleClose = () => {
    reset();
    onClose();
  };

  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_id', selectedIds?.[0]],
    queryFn: () => fetchContractById(selectedIds?.[0] as string),
    enabled: !!(selectedIds?.length === 1),
  });

  const { data: allContractTypes, isLoading: contractTypeLoading } = useQuery({
    queryKey: ['contract_data'],
    queryFn: fetchContractType,
    select: (response: any) => {
      return response?.results;
    },
  });

  const { data: projectData, isLoading: projectLoading } = useQuery({
    queryKey: ['project_data'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) =>
      response.results.filter((data: any) => data.name !== ''),
  });

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['group_data'],
    queryFn: fetchGroups,
  });

  useEffect(() => {
    if (contract_data) {
      const getContractTypeId = contract_data?.contract_type?.[0]?.id;
      const getProjectIds = contract_data?.projects?.map(
        (item: any) => item?.id
      );
      const filterGroups = groupData?.filter((item: any) => {
        if (contract_data?.groups?.includes(item?.name)) {
          return item?.id;
        }
      });
      const getGroupIds = filterGroups?.map((item: any) => item?.id);
      if (selectedIds?.length === 1) {
        setValue('groups', getGroupIds);
        setValue('contract_type', getContractTypeId);
        setValue('projects', getProjectIds);
      }
    }
  }, [contract_data, groupData, selectedIds]);

  const { mutate: editReplaceMutation, isPending: editReplaceLoading } =
    useMutation({
      mutationKey: ['edit_replace_ids'],
      mutationFn: async (payload: any) => editReplaceTags(payload),

      onSuccess: () => {
        enqueueSnackbar('Tags updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleClose();
        queryClient.invalidateQueries({ queryKey: ['contract_table_data'] });
      },
      onError: () => {
        enqueueSnackbar('Failed to update tags!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: addNewProject, isPending: addProjectLoading } = useMutation({
    mutationKey: ['add_new_projects'],
    mutationFn: async (payload: any) => addProject(payload),

    onSuccess: () => {
      enqueueSnackbar('Project added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsAddNewProject(false);
      resetField('projectName');
      queryClient.invalidateQueries({ queryKey: ['project_data'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to add project!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddProject = () => {
    const payload = [
      {
        comment: '',
        displayName: project_name,
        name: project_name,
      },
    ];
    addNewProject(payload);
  };

  const onSubmit = (data: any) => {
    data.groups = data?.groups?.length === 0 ? undefined : data?.groups;
    const payload = {
      contract_ids: selectedIds,
      ...data,
    };
    if (payload?.contract_type) {
      payload.contract_type = [payload?.contract_type];
    }
    if (selectedIds?.length === 1 && !data?.groups?.length) {
      enqueueSnackbar('Please select at least one group', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    } else {
      editReplaceMutation(payload);
    }
  };

  return (
    <CustomModal title={title} open={open} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} width="500px" pt="20px">
          {replace && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <ErrorOutlineIcon style={{ color: '#938F99' }} />
              <Typography color="#938F99">
                All existing tags for the select contracts will be replaced
              </Typography>
            </Stack>
          )}
          <RISelectComponent
            name="contract_type"
            control={control}
            label="Contract Type"
            options={sortListAlphabetical(allContractTypes)}
            loading={contractTypeLoading}
            valueKey="id"
            renderCustomComponent={(value: any, props) => (
              <CustomChip {...props} label={value?.name} />
            )}
          />

          <RISelectComponent
            name="groups"
            control={control}
            label="Groups *"
            options={sortListAlphabetical(groupData)}
            loading={groupLoading}
            isMultiselect={true}
            valueKey="id"
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                {...props}
                icon={
                  <TagIcon
                    style={{
                      color: '#6D264C',
                    }}
                  />
                }
                label={value?.name}
              />
            )}
          />

          <RISelectComponent
            name="projects"
            control={control}
            label="Project"
            options={sortListAlphabetical(projectData)}
            loading={projectLoading}
            isMultiselect={true}
            valueKey="id"
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                {...props}
                icon={
                  <WorkOutlineIcon
                    style={{
                      color: '#6D264C',
                    }}
                  />
                }
                label={value?.name}
              />
            )}
          />
          {isAddNewProject && (
            <ControlledTextField
              name="projectName"
              control={control}
              label="Project Name"
              fullWidth
            />
          )}
        </Stack>
        <Stack
          direction="row"
          marginTop={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <LoadingButton
              loading={editReplaceLoading}
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>

          {!isAddNewProject ? (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsAddNewProject(true)}
            >
              Add project
            </Button>
          ) : (
            <LoadingButton
              loading={addProjectLoading}
              variant="outlined"
              onClick={() => handleAddProject()}
              disabled={!project_name.trim()}
            >
              Save project
            </LoadingButton>
          )}
        </Stack>
      </form>
    </CustomModal>
  );
};

export default ReplaceAndEditTag;
