import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import ClauseComponentWatcher from './DocumentView/Component/ClauseComponent/Saga/clauseComponentSaga';
import DocumentWatcher from './DocumentView/Saga/documentSaga';
import { mainReducer } from './Utils/MainReducer/mainReducer';

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(
  mainReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleWare))
);

sagaMiddleWare.run(DocumentWatcher);
sagaMiddleWare.run(ClauseComponentWatcher);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import createSagaMiddleware from 'redux-saga';

// import ClauseComponentWatcher from './DocumentView/Component/ClauseComponent/Saga/clauseComponentSaga';
// import DocumentWatcher from './DocumentView/Saga/documentSaga';
// import draft from './Redux/draftSlice';
// import { api } from './Services/api';
// import { mainReducer } from './Utils/MainReducer/mainReducer';

// // Initialize saga middleware
// const sagaMiddleware = createSagaMiddleware();

// // Combine reducers
// const rootReducer = combineReducers({
//   main: mainReducer,
//   draft,
//   [api.reducerPath]: api.reducer,
// });

// // Configure the store
// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: ['splitApi/executeQuery/fulfilled', 'auth/setInstance'],
//       },
//     }).concat(sagaMiddleware, api.middleware),
//   devTools: process.env.NODE_ENV !== 'production',
// });

// // Run saga watchers
// sagaMiddleware.run(DocumentWatcher);
// sagaMiddleware.run(ClauseComponentWatcher);

// // Define type utilities
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
