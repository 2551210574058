import React, { Dispatch, SetStateAction, useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';

import CustomPagination from './Pagination';

interface Props extends DataGridProps {
  rows: any;
  columns: GridColDef[];
  rowCount?: number;
  isLoading?: boolean;
  setSelectedRow?: Dispatch<SetStateAction<GridRowId[]>>;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  checkboxSelection?: boolean;
  maxSelected?: number;
  selectedRow?: GridRowId[];
  disableMultipleRowSelection?: boolean;
  hideFooterPagination?: boolean;
  handleRowsSelection?: (newSelectedRow: any[], rows: any[]) => void;
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

const DataGridTable = (props: Props) => {
  const {
    rows,
    columns,
    rowCount,
    isLoading,
    setSelectedRow,
    pageNumber,
    setPageNumberChange,
    setSorting,
    maxSelected,
    selectedRow,
    checkboxSelection = true,
    hideFooterPagination,
    handleRowsSelection,
    ...rest
  } = props;

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setPaginationModel({ page: pageNumber, pageSize: 10 });
  }, [pageNumber]);

  const handlePaginationModalCHange = React.useCallback(
    (value: GridPaginationModel) => {
      setPaginationModel(value);
    },
    []
  );

  useEffect(() => {
    setPageNumberChange(paginationModel?.page);
  }, [paginationModel?.page]);

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      setSorting?.([...sortModel]);
    },
    [setSorting]
  );

  const handleRowSelectionChange = (newRowSelectionModel: GridRowId[]) => {
    if (maxSelected && newRowSelectionModel.length > maxSelected) {
      const selectionSet = new Set(selectedRow);
      const result = newRowSelectionModel.filter((s) => !selectionSet.has(s));

      setSelectedRow?.(result);
    } else {
      setSelectedRow?.(newRowSelectionModel);
      if (handleRowsSelection) {
        handleRowsSelection(newRowSelectionModel, rows);
      }
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <DataGrid
        pagination
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        pageSizeOptions={[10]}
        rowCount={rowCount || 0}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationModalCHange}
        disableColumnMenu
        hideFooterPagination={hideFooterPagination}
        slots={{
          loadingOverlay: LinearProgress,
          columnMenu: CustomColumnMenu,
          pagination: CustomPagination,
        }}
        rowSelectionModel={selectedRow}
        onRowSelectionModelChange={handleRowSelectionChange}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'riPrimary.10',
            fontSize: 16,
            borderRadius: '50px',
            borderBottom: 'none',
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default DataGridTable;
