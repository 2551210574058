import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ControlledCheckbox from '../../RiverusUI/Components/ControlledCheckbox';
import CustomModal from '../../RiverusUI/Components/CustomModal';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  handleRenewConfirmation: (renewal_type: string) => void;
  buttonLoading: boolean;
}

const RenewConfirmation: React.FC<IProps> = ({
  open,
  onClose,
  handleRenewConfirmation,
  buttonLoading,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenWarn, setIsOpenWarn] = useState<boolean>(false);

  const template = watch('template') || '';

  useEffect(() => {
    if (template) {
      setIsOpenWarn(true);
    }
  }, [template]);

  const onSubmit = (data: any) => {
    if (!data?.template && !data?.request_draft) {
      enqueueSnackbar('Please select any one option!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      if (data?.template) {
        handleRenewConfirmation('template');
      }
      if (data?.request_draft) {
        handleRenewConfirmation('request_draft');
      }
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        open={open}
        handleClose={onClose}
        title="Renew Contract"
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2}>
            <Typography fontWeight="700">Select any one option</Typography>
            <ControlledCheckbox
              name="template"
              label="Generate renewal draft using the same template from which the contract was created"
              control={control}
              onChange={() => {
                setValue('request_draft', false);
              }}
            />

            <ControlledCheckbox
              name="request_draft"
              label="request a renewal draft from legal"
              control={control}
              onChange={() => {
                setValue('template', false);
              }}
            />

            <Stack direction="row">
              <LoadingButton
                variant="contained"
                type="submit"
                loading={buttonLoading}
              >
                Renew Contract
              </LoadingButton>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </CustomModal>
      {isOpenWarn && (
        <Dialog open={isOpenWarn}>
          <Stack width="fit-content" padding={3} spacing={2}>
            <Typography>
              If you choose to renew from template, negotiated changes that may
              be part of the executed version will not automatically reflected.
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" onClick={() => setIsOpenWarn(false)}>
                Ok
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default RenewConfirmation;
