import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';

import LinkTemplateDialog from './LinkTemplateDialog';
import UploadDraftDialog from './UploadDraftDialog';
import VersionTable from './VersionTable';
import { useUserData } from '../../../App/Component/UserDataProvider';
import ShareModal from '../../../DocumentLibrary/Component/ShareModal/ShareModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import {
  deleteCompareVersion,
  deleteDraftVersionHistory,
  dropUnDropDraft,
  editDraftData,
  fetchCompareVersionHistory,
  fetchKDPTagDeviations,
  fetchTemplate,
  fetchVersionHistory,
  getDraftById,
  getS3ReferencePresignedURL,
} from '../../../Services/Draft';
import { uploadCompareVersion, uploadContractMetaData } from '../../DA';
import { draftStatus } from '../../State/DraftState';
import ComparisonDialog from '../ComparisonDialog';
import DraftViewAlert from '../DraftViewAlert';
import { automaticallyDroppedMsg, droppedMsg } from '../Helper';
import { dropNotShowingStatus, restrictionStatus } from '../StaticData';

interface Props {
  draftRowData: any;
  setDraftRowData: Dispatch<SetStateAction<any>>;
  handleVersionDrawerClose: VoidFunction;
}

const uploadVersionStatus = [
  draftStatus?.COUNTER_PARTY_DRAFT_CREATED,
  draftStatus?.INTERNAL_DRAFT_CREATED,
  draftStatus?.SIGNATURE_ABORTED,
];

const VersionDrawer: React.FC<Props> = ({
  draftRowData,
  setDraftRowData,
  handleVersionDrawerClose,
}) => {
  const [versionTableData, setVersionTableData] = useState<string[]>([]);
  const [versionHistory, setVersionHistory] = useState<any>([]);
  const [compareVersionHistory, setCompareVersionHistory] = useState<string[]>(
    []
  );
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [isShowCompareBtn, setIsShowCompareBtn] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(false);
  const [openUploadNew, setOpenUploadNew] = useState(false);
  const [openLinkTemplate, setOpenLinkTemplate] = useState(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openComparisonDrawer, setOpenComparisonDrawer] =
    useState<boolean>(false);
  const [compareFileName, setCompareFileName] = useState<any[]>([]);
  const [selectedDraftsLink, setSelectedDraftsLink] = useState<string[]>([]);
  const [showDraftLockedAlert, setShowDraftLockedAlert] =
    useState<boolean>(false);
  const [openDropUndrop, setOpenDropUndrop] = useState<boolean>(false);
  const [comparisonResponse, setComparisonResponse] = useState<any>(null);

  const { enqueueSnackbar } = useSnackbar();
  const selectedDraftId = draftRowData?.draftID || draftRowData?.id;
  const queryClient = useQueryClient();

  const { user_id } = useUserData();

  const selectedComparedDrafts = useMemo(
    () => selectedRow?.filter((item: any) => item?.compare_versions),
    [selectedRow]
  );

  const userIsOwner = useMemo(
    () => draftRowData?.owners?.find((owner: any) => owner?.id === user_id),
    [draftRowData, user_id]
  );

  const userIsCreator = useMemo(
    () => draftRowData?.creator?.id === user_id,
    [draftRowData, user_id]
  );

  const { isLoading: versionHistoryLoading } = useQuery({
    queryKey: ['version_history', selectedDraftId],
    queryFn: async () => {
      const response = await fetchVersionHistory(selectedDraftId);
      setVersionHistory(response?.results);
      return response?.results;
    },
    enabled: !!selectedDraftId,
  });

  useQuery({
    queryKey: ['compare_history', selectedDraftId],
    queryFn: async () => {
      const response = await fetchCompareVersionHistory(selectedDraftId);
      setCompareVersionHistory(response?.results);
      return response?.results;
    },
    enabled: !!selectedDraftId,
  });

  const { data: mergeTagDeviations, isLoading } = useQuery({
    queryKey: ['fetch_merge_tag_deviations', draftRowData?.id],
    queryFn: () => fetchKDPTagDeviations(draftRowData?.id, 'merge_tag'),
    select: (response: any) => {
      const filteredDeviationsList = response?.results?.filter(
        (listItem: any) => {
          if (
            (listItem?.approval_status === 'approval_pending' ||
              listItem?.approval_status === null) &&
            listItem?.status === 'deviant'
          ) {
            return listItem;
          }
        }
      );
      return filteredDeviationsList || [];
    },
    enabled: !!(
      draftRowData?.id &&
      draftRowData?.status?.toLowerCase().includes('internal draft created') &&
      draftRowData?.createFrom === 'template' &&
      draftRowData?.version === 1
    ),
  });

  const recallQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ['version_history', selectedDraftId],
    });
    queryClient.invalidateQueries({
      queryKey: ['compare_history', selectedDraftId],
    });
    queryClient.invalidateQueries({ queryKey: ['drafts'] });
  };

  useEffect(() => {
    if (versionHistory?.length !== 0 || compareVersionHistory?.length !== 0) {
      const newVersionHistory: any[] = [];
      versionHistory?.forEach((item: any) => {
        if (item.drop_details && item.drop_details.length > 0) {
          for (let i = 0; i < item.drop_details.length; i++) {
            newVersionHistory.push({
              ...item,
              id: item.drop_details[i]?.id,
              dropUndropDetails: item.drop_details[i],
            });
          }
        }
        newVersionHistory?.push(item);
      });

      setVersionTableData([...newVersionHistory, ...compareVersionHistory]);
    }
  }, [versionHistory, compareVersionHistory]);

  const handleSelectionChange = (selection: any) => {
    const selectedRowData: any = versionTableData.filter((row: any) =>
      selection.includes(row.id)
    );

    // conditions for share draft button
    if (selectedRowData?.length === 1) {
      setCanShare(true);
    } else {
      setCanShare(false);
    }

    // conditions for compare drafts button
    if (
      selectedRowData?.length === 2 &&
      selectedRowData?.[0]?.version !== 0 &&
      selectedRowData?.[1]?.version !== 0
    ) {
      setIsShowCompareBtn(true);
      setSelectedDraftsLink([
        selectedRowData?.[0]?.id,
        selectedRowData?.[1]?.id,
      ]);
    } else {
      setIsShowCompareBtn(false);
    }

    setSelectedRow(selectedRowData);
  };

  const handleUploadModalClose = () => {
    setOpenUploadNew(false);
  };

  const { mutate: viewReferenceDocument } = useMutation({
    mutationKey: ['view_reference_document_link'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: (response: any) => {
      const url = response?.response?.presigned_url;
      const fileExtension = url.split('.').pop()?.split('?')[0] || 'pdf';
      fetch(url)
        .then((r) => r.blob())
        .then((blobData) => {
          saveAs(
            blobData,
            `${selectedRow[0]?.contractName + `.${fileExtension}`}`
          );
        });
    },
  });

  const downloadVersionFile = async () => {
    if (userIsOwner) {
      const payload = {
        file_type: 'view_document',
        link: selectedRow[0]?.link || selectedRow[0]?.template_link,
      };
      viewReferenceDocument({ body: payload });
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const { mutate: uploadContractData } = useMutation({
    mutationFn: uploadContractMetaData,
    onSuccess: (responseData) => {
      setDraftRowData(responseData);
      recallQuery();
      handleVersionDrawerClose();
    },
  });

  const openCreateDraftDialog = () => {
    if (
      draftRowData?.createFrom === 'template' &&
      (draftRowData?.status.toLowerCase() === 'draft creation pending' ||
        draftRowData?.version === 0)
    ) {
      enqueueSnackbar(
        'Version should not be uploaded until draft generation from template is approved',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      return;
    } else if (
      (draftRowData?.createFrom === 'counter_party' ||
        draftRowData?.createFrom === 'support_document') &&
      draftRowData?.status === 'Requisition Approval Pending'
    ) {
      enqueueSnackbar(
        'Version should not be uploaded until draft generation from counter party or requisition draft is approved',
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      return;
    }
    setOpenUploadNew(true);
  };

  const handleContractNameClick = React.useCallback(() => {
    if (userIsOwner || userIsCreator) {
      openCreateDraftDialog();
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  }, [draftRowData]);

  const shareVersion = () => {
    if (userIsOwner) {
      if (selectedRow?.length === 1 && selectedRow[0]?.link) {
        if (selectedRow[0]?.is_locked) {
          setShowDraftLockedAlert(true);
        } else {
          setOpenShareModal(true);
        }
      } else {
        setOpenShareModal(true);
      }
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow([]);
  };

  const handleOpenDeleteDialog = () => {
    if (userIsOwner) {
      selectedRow.map((data: any) => {
        if (data?.version === 1) {
          enqueueSnackbar('V1 draft cannot be deleted.', {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return;
        } else {
          setOpenDeleteDialog(true);
        }
      });
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const { mutate: compareVersionMutation } = useMutation({
    mutationKey: ['compare-version'],
    mutationFn: uploadCompareVersion,
    onSuccess: (response: any) => {
      setComparisonResponse(response);
      recallQuery();
    },
  });

  const handleCompareVersion = async () => {
    if (userIsOwner) {
      const higherDraft = selectedRow?.filter((item: any, index: any) => {
        if (item[index] > item[index + 1]) {
          return item?.draftID;
        } else {
          return item?.draftID;
        }
      });
      const name1 = `V${selectedRow?.[0]?.version}-${selectedRow?.[0]?.contractName}`;
      const name2 = `V${selectedRow?.[1]?.version}-${selectedRow?.[1]?.contractName}`;
      const payload = {
        draftID: higherDraft[0]?.draftID,
        version: higherDraft[0]?.version,
        compare_versions: {
          data: selectedRow,
          visible_version: `V${selectedRow?.[0].version}/V${selectedRow?.[1].version}`,
        },
      };
      compareVersionMutation(payload);
      setOpenComparisonDrawer(true);
      setCompareFileName([name1, name2]);
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const { mutate: updateDraftData } = useMutation({
    mutationKey: ['update_draft_data'],
    mutationFn: editDraftData,
    onSuccess: () => {
      recallQuery();
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteDraftVersion, isPending: loadingVersion } = useMutation(
    {
      mutationFn: deleteDraftVersionHistory,
      onSuccess: async () => {
        enqueueSnackbar('Version deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        let contractName = null;
        let getDraftData = null;
        if (Array.isArray(selectedRow)) {
          const selectedVersionDrafts = selectedRow?.filter(
            (item) => !item?.compare_versions && item?.version !== 1
          );
          if (selectedVersionDrafts?.length > 0) {
            const deletedVersions = selectedVersionDrafts.map(
              (draft) => draft?.version
            );
            const lowestDeletedVersion = Math.min(...deletedVersions);
            const highestDeletedVersion = Math.max(...deletedVersions);

            const highestVersionDraft = selectedVersionDrafts?.find(
              (draft) => draft?.version === highestDeletedVersion
            );
            contractName = highestVersionDraft?.contractName;
            const allDrafts = highestVersionDraft?.all_drafts;

            if (Array.isArray(allDrafts)) {
              const higherVersionExists = allDrafts?.some((draft) => {
                const version = Object.values(draft)[0] as number;
                return version > highestDeletedVersion;
              });

              if (!higherVersionExists) {
                const availableVersions = allDrafts
                  ?.map((draft) => Object.values(draft)[0] as number)
                  ?.sort((a, b) => a - b);

                const nextLowerVersion = availableVersions
                  ?.reverse()
                  ?.find((version) => version < lowestDeletedVersion);

                if (nextLowerVersion !== undefined) {
                  const previousVersionDraft = allDrafts?.find((draft) => {
                    const version = Object.values(draft)[0];
                    return version === nextLowerVersion;
                  });
                  if (previousVersionDraft) {
                    const previousDraftId =
                      Object.keys(previousVersionDraft)[0];

                    // For ref: This api call is needed to compare contract names to call updateDraftData or not
                    getDraftData = await queryClient.fetchQuery({
                      queryKey: ['get_draft_by_id', previousDraftId],
                      queryFn: async () => {
                        return await getDraftById(previousDraftId);
                      },
                    });
                    if (getDraftData?.contractName !== contractName) {
                      const payload = {
                        id: previousDraftId,
                        body: {
                          contractName: contractName,
                        },
                      };
                      await updateDraftData(payload);
                    }
                  }
                }
              }
            }
          }
        }
        handleCloseDeleteDialog();
        if (getDraftData?.contractName === contractName) {
          recallQuery();
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to delete the version!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  const { mutate: deleteComparedDraft } = useMutation({
    mutationFn: deleteCompareVersion,
    onSuccess: () => {
      enqueueSnackbar('Compare version deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteDialog();
      recallQuery();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete the compare version!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleDeleteConfirmation = () => {
    const selectedVersionDraft = selectedRow?.filter(
      (item: any) => !item?.compare_versions && item?.version !== 1
    );
    if (selectedVersionDraft?.length > 0) {
      const draft_ids = selectedVersionDraft?.map((item: any) => item?.id);
      deleteDraftVersion(draft_ids);
    }
    if (selectedComparedDrafts?.length > 0) {
      const compare_ids = selectedComparedDrafts.map((item: any) => item?.id);
      deleteComparedDraft(compare_ids);
    }
    handleVersionDrawerClose();
  };

  const nonRestrictedUsers = useMemo(() => {
    return (
      Array.isArray(draftRowData?.approvalTypes) &&
      (draftRowData?.approvalTypes.includes(user_id) ||
        draftRowData?.approvalTypes.includes(draftRowData?.creator?.id) ||
        userIsOwner)
    );
  }, [draftRowData?.approvalTypes, user_id]);

  const handleLinkTemplateClick = React.useCallback(() => {
    if (userIsOwner || userIsCreator) {
      setOpenLinkTemplate(true);
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  }, [draftRowData]);

  const { data: getTemplateList, isLoading: loadingTemplateList } = useQuery({
    queryKey: ['template_list'],
    queryFn: fetchTemplate,
    select: (response: any) => response.results,
  });

  const templateList = useMemo(
    () =>
      getTemplateList?.filter((items: any) => {
        return items?.contract_type?.id === draftRowData?.contractType?.id;
      }),
    [getTemplateList, draftRowData?.contractType?.id]
  );

  const handleLinkModalClose = () => {
    setOpenLinkTemplate(false);
  };

  const dropUnDropText = useMemo(
    () =>
      draftRowData?.status === draftStatus?.Draft_Dropped ? 'Undrop' : 'Drop',
    [draftRowData?.status]
  );

  const { mutate: dropUnDropMutation, isPending: loadingDrop } = useMutation({
    mutationFn: dropUnDropDraft,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      setOpenDropUndrop(false);
      handleVersionDrawerClose();
    },
  });

  const handleDropUndrop = () => {
    let payload = {
      draft_ids: [draftRowData?.id],
      drop_status: true,
    };
    if (draftRowData?.status === draftStatus?.Draft_Dropped) {
      payload = {
        draft_ids: [draftRowData?.id],
        drop_status: false,
      };
    }
    dropUnDropMutation(payload);
  };

  const isShowDropUndrop = useMemo(() => {
    return !dropNotShowingStatus?.includes(draftRowData?.status);
  }, [draftRowData?.status]);

  const isExecuted = useMemo(() => {
    return versionTableData?.some(
      (row: any) => row?.status === draftStatus.CONTRACT_EXECUTED_SUCCESSFULLY
    );
  }, [versionTableData]);

  return (
    <Stack spacing={2} sx={{ margin: '15px' }}>
      <Typography fontSize="13px">
        Use Version History to upload a new version, compare previous versions
        and download previous and current versions.
      </Typography>

      <Typography fontSize="13px">
        To compare two versions, start by selecting any two versions below and
        use the icon to compare.
      </Typography>

      <Stack spacing={1}>
        <Stack direction="row" justifyContent="flex-end">
          {draftRowData?.status === draftStatus?.Automatically_Dropped && (
            <Chip label="connected document is terminated" />
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          {!selectedRow.length &&
            userIsOwner &&
            isShowDropUndrop &&
            draftRowData?.status !== draftStatus?.Automatically_Dropped && (
              <Button
                variant="contained"
                onClick={() => setOpenDropUndrop(true)}
              >
                {dropUnDropText} this Draft
              </Button>
            )}
        </Stack>
        <Grid container justifyContent="space-between" alignItems="start">
          {selectedRow.length === 0 &&
            draftRowData?.created_from_email &&
            draftRowData?.version === 0 &&
            (versionHistory?.[0]?.status === 'Draft Upload Pending' ||
              (versionHistory?.[0]?.status === 'Draft Creation Pending' &&
                versionHistory?.[0]?.template_link)) && (
              <Stack alignItems="center">
                <Button
                  variant="outlined"
                  startIcon={<LinkIcon />}
                  onClick={handleLinkTemplateClick}
                  sx={{
                    whiteSpace: 'nowrap',
                    padding: '10px 12px',
                    fontSize: '13px',
                  }}
                  disabled={templateList?.length === 0}
                >
                  {versionHistory?.[0]?.template_link
                    ? 'Replace / Unlink a template'
                    : 'Link a template'}
                </Button>
                {templateList?.length === 0 && (
                  <Typography variant="caption" color="#bdafb3">
                    0 Templates Present
                  </Typography>
                )}
              </Stack>
            )}

          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            {selectedRow.length === 0 && (
              <Tooltip
                title={
                  draftRowData?.status === draftStatus?.Draft_Dropped
                    ? droppedMsg
                    : draftRowData?.status ===
                        draftStatus?.Automatically_Dropped
                      ? automaticallyDroppedMsg
                      : ''
                }
                arrow
                disableHoverListener={
                  !restrictionStatus?.includes(draftRowData?.status)
                }
              >
                <span>
                  <LoadingButton
                    variant="outlined"
                    startIcon={<FileUploadOutlinedIcon />}
                    sx={{
                      padding: '10px 12px',
                      fontSize: '13px',
                    }}
                    loading={isLoading}
                    disabled={
                      restrictionStatus?.includes(draftRowData?.status)
                        ? true
                        : nonRestrictedUsers &&
                            draftRowData?.createFrom === 'request_draft' &&
                            draftRowData?.version === 0 &&
                            draftRowData?.status ===
                              draftStatus?.DRAFT_UPLOAD_PENDING
                          ? false
                          : draftRowData?.created_from_email &&
                              draftRowData?.version === 0 &&
                              versionHistory?.[0]?.status ===
                                'Draft Upload Pending' &&
                              !versionHistory?.[0]?.template_link
                            ? false
                            : draftRowData?.createFrom === 'template'
                              ? draftRowData?.version === 0 ||
                                (draftRowData?.status ===
                                  draftStatus?.INTERNAL_DRAFT_CREATED &&
                                  mergeTagDeviations?.length > 0 &&
                                  draftRowData?.version === 1)
                                ? true
                                : !uploadVersionStatus?.includes(
                                    draftRowData?.status
                                  ) || false
                              : !uploadVersionStatus?.includes(
                                  draftRowData?.status
                                ) || false
                    }
                    onClick={handleContractNameClick}
                  >
                    Upload a new version
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
            {selectedRow?.length === 1 &&
              selectedComparedDrafts?.length === 0 && (
                <Stack direction="row" spacing={1}>
                  {canShare && (
                    <IconButton onClick={() => shareVersion()}>
                      <ShareOutlinedIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => downloadVersionFile()}>
                    <SaveAltOutlinedIcon />
                  </IconButton>
                </Stack>
              )}
            {selectedRow?.length === 2 &&
              selectedComparedDrafts?.length === 0 &&
              isShowCompareBtn && (
                <IconButton onClick={() => handleCompareVersion()}>
                  <CompareOutlinedIcon />
                </IconButton>
              )}
            {selectedRow?.length > 0 && (
              <Tooltip
                title={
                  isExecuted
                    ? 'Once the contract is successfully executed, versions cannot be deleted!'
                    : ''
                }
                arrow
              >
                <Stack>
                  <IconButton
                    onClick={handleOpenDeleteDialog}
                    disabled={
                      restrictionStatus?.includes(draftRowData?.status) ||
                      isExecuted
                    }
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Grid>
      </Stack>

      <VersionTable
        versionTableData={versionTableData}
        handleSelectionChange={handleSelectionChange}
        loadingTable={versionHistoryLoading}
        draftRowData={draftRowData}
      />

      {openLinkTemplate && (
        <LinkTemplateDialog
          open={openLinkTemplate}
          onClose={handleLinkModalClose}
          draftData={draftRowData}
          templateList={templateList}
          loadingTemplateList={loadingTemplateList}
          versionHistory={versionHistory}
        />
      )}

      {openUploadNew && (
        <UploadDraftDialog
          open={openUploadNew}
          onClose={handleUploadModalClose}
          draftData={draftRowData}
          uploadContractData={uploadContractData}
        />
      )}

      {openShareModal && (
        <ShareModal
          openModal={openShareModal}
          handleCloseShareBtn={() => setOpenShareModal(false)}
          selectedList={selectedRow}
          type="stylus"
        />
      )}

      <ReusableConfirmationModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmation}
        title="Delete Draft Version"
        confirmBtnText="Yes, Delete"
        cancelBtnText="Cancel"
        buttonLoading={loadingVersion}
      >
        <Typography>
          Are you sure you want to delete these drafts version ?
        </Typography>
      </ReusableConfirmationModal>

      {openComparisonDrawer && (
        <ComparisonDialog
          openComparisonDrawer={openComparisonDrawer}
          setComparisonDrawer={setOpenComparisonDrawer}
          draftDetails={draftRowData}
          selectedDraftsLink={selectedDraftsLink}
          onClose={() => {
            setOpenComparisonDrawer(false);
          }}
          compareFileName={compareFileName}
          comparisonResponse={comparisonResponse}
        />
      )}

      {showDraftLockedAlert && (
        <DraftViewAlert
          isOpen={showDraftLockedAlert}
          onClose={() => setShowDraftLockedAlert(false)}
          data={selectedRow?.[0]?.locked_by}
        />
      )}
      {openDropUndrop && (
        <ReusableConfirmationModal
          open={openDropUndrop}
          onClose={() => setOpenDropUndrop(false)}
          title={`${dropUnDropText} Draft`}
          cancelBtnText="Cancel"
          confirmBtnText={`${dropUnDropText} Draft`}
          onConfirm={handleDropUndrop}
          buttonLoading={loadingDrop}
        >
          <Stack spacing={2}>
            <Typography>
              Are you sure you want to {dropUnDropText} this draft?
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default VersionDrawer;
