import React from 'react';

import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  mergeTagData: any;
  handleGenerateDraft: VoidFunction;
}

const GenerateDraftDialog: React.FC<Props> = ({
  open,
  onClose,
  mergeTagData,
  handleGenerateDraft,
}) => {
  return (
    <CustomModal title="Generate Draft" open={open} handleClose={onClose}>
      <Stack spacing={2} marginY="20px">
        <Typography>
          The following field values will require approvals from people as per
          the defined policies.
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, border: '1px solid black' }}>
            <TableHead>
              <TableRow>
                <TableCell>Field name</TableCell>
                <TableCell>Condition</TableCell>
                <TableCell>Field value</TableCell>
                <TableCell>Approvers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergeTagData?.map((row: any) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.draft_datapoint?.field_name}
                  </TableCell>
                  <TableCell>{row?.condition?.condition}</TableCell>
                  <TableCell>
                    {row?.draft_datapoint?.field_value || '--'}
                  </TableCell>
                  <TableCell>
                    {row?.condition?.approvers?.map(
                      (approver: any, index: number) => (
                        <Typography key={index}>
                          {approver?.first_name}
                        </Typography>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={handleGenerateDraft}>
            Generate draft and request approvals
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Go, back
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default GenerateDraftDialog;
