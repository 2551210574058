import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';

interface Props {
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  contractData?: any;
}

const DictionaryTable = (props: Props) => {
  const {
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
    setSelectedRow,
    contractData,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleContractNameClick = React.useCallback((id: string) => {
    window.open(`/admin/data-dictionary/${id}`);
  }, []);

  useEffect(() => {
    if (!filters?.displayName) {
      setSearchDraft(false);
    }
    if (!filters?.name) {
      setSearchName(false);
    }
  }, [filters]);

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'displayName',
        headerName: 'Display Name',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="displayName"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, displayName: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) => {
                if (e.target.value?.length) {
                  handleFilterChange('displayName', e.target.value);
                }
              }}
            />
          ) : (
            <TableHeaderWithSearch
              title="Display Name"
              setIsSearch={setSearchDraft}
            />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.displayName}
              width={Params.colDef.computedWidth}
              onClick={() => handleContractNameClick(Params.row?.id)}
              cellStyle={{
                cursor: 'pointer',
              }}
              icon={
                Params?.row?.creation_type === 'custom' ? (
                  <NameAvatar
                    firstName={Params?.row?.created_by?.first_name}
                    lastName={Params?.row?.created_by?.last_name}
                    style={{
                      width: '24px',
                      height: '24px',
                      fontSize: '12px',
                      marginLeft: '3px',
                    }}
                  />
                ) : (
                  <div />
                )
              }
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Contract Type',
        minWidth: 240,
        flex: 1,
        sortable: !searchName,
        renderHeader: () => {
          return searchName ? (
            <TableSearchInputComponent
              key="name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchName(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('name', e.target.value)
              }
            />
          ) : (
            <Stack onClick={(e) => e.stopPropagation()}>
              <TableHeaderWithSearch title="Name" setIsSearch={setSearchName} />
            </Stack>
          );
        },
      },
      {
        field: 'used_in_drafts',
        headerName: 'Contracts',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (Params: any) => {
          return (
            <div>
              {Params.row.used_in_templates
                ? `${Params.row.used_in_templates} Templates ${Params.row.used_in_drafts ? '/ ' : ''}`
                : null}
              {Params.row.used_in_drafts
                ? `${Params.row.used_in_drafts} Drafts`
                : null}
            </div>
          );
        },
      },
    ],
    [
      searchDraft,
      searchName,
      setFilters,
      handleFilterChange,
      handleContractNameClick,
      filters,
      contractData,
    ]
  );

  return (
    <React.Fragment>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        setSelectedRow={setSelectedRow}
      />
    </React.Fragment>
  );
};

export default DictionaryTable;
