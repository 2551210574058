import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ShareChip from '../DocumentLibrary/Component/ShareModal/ShareChip';
import { sortListAlphabetical } from '../Draft/Component/Helper';
import CustomModal from '../RiverusUI/Components/CustomModal';
import RISelectComponent from '../RiverusUI/Components/SelectComponent';
import { deleteUserRoles, editUserRoles } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  rolesData: any;
  editDetails: any;
  usersTableKey: string;
  autoSelectedRolesIds: string[];
  modalClose: VoidFunction;
  actionType: any;
}

const AssignRolesModal: React.FC<Props> = ({
  open,
  onClose,
  rolesData,
  editDetails,
  usersTableKey,
  autoSelectedRolesIds,
  modalClose,
  actionType,
}) => {
  const methods = useForm();
  const { control, setValue, watch, handleSubmit } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedRoles = watch('roles');

  useEffect(() => {
    if (editDetails) {
      setValue('roles', autoSelectedRolesIds);
    }
  }, [editDetails, setValue, autoSelectedRolesIds]);

  const { mutate: editUserRoleMutation } = useMutation({
    mutationFn: editUserRoles,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('Roles added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
      modalClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to add roles!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteUserRoleMutation } = useMutation({
    mutationFn: deleteUserRoles,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('Role removed successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
      modalClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to remove role!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = () => {
    const newRoles = selectedRoles.filter(
      (roleId: string) => !editDetails?.roles?.includes(roleId)
    );
    const deleteRoles = editDetails?.roles?.filter(
      (roleId: string) => !selectedRoles.includes(roleId)
    );
    const addPayload = {
      id: editDetails?.keycloak_id,
      body: newRoles.map((roleId: string) => {
        const role = rolesData.find((item: any) => item?.name === roleId);
        return {
          id: role?.id,
          name: role?.name,
          description: role?.description,
          containerId: role?.containerId,
          composite: role?.composite,
          clientRole: role?.clientRole,
        };
      }),
    };
    const deletePayload = {
      id: editDetails?.keycloak_id,
      body: deleteRoles
        ?.map((roleName: string) => {
          const role = rolesData?.find((item: any) => item?.name === roleName);
          return role ? { id: role?.id, name: role?.name } : null;
        })
        ?.filter((role: any) => role !== null),
    };
    if (addPayload.body.length > 0) {
      editUserRoleMutation(addPayload);
    }
    if (deletePayload.body.length > 0) {
      deleteUserRoleMutation(deletePayload);
    }
    if (addPayload.body.length === 0 && deletePayload.body.length === 0) {
      enqueueSnackbar('No changes to roles!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <CustomModal
      maxWidth="md"
      title={actionType === 'assign' ? 'Assign Roles' : 'Unassign Roles'}
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack width="500px" sx={{ padding: '30px 10px 10px' }} spacing={3}>
            <RISelectComponent
              name="roles"
              control={control}
              label="Select Roles"
              valueKey="name"
              options={sortListAlphabetical(rolesData)}
              isMultiselect
              disableOptionCondition={(role: any) =>
                actionType === 'assign'
                  ? editDetails?.roles?.includes(role.name)
                  : !editDetails?.roles?.includes(role.name)
              }
              renderCustomComponent={(value: any, props) => {
                const isDisabled =
                  actionType === 'assign'
                    ? editDetails?.roles?.includes(value?.name)
                    : !editDetails?.roles?.includes(value?.name);

                return (
                  <ShareChip
                    key={value?.name}
                    {...props}
                    label={value?.name}
                    disabled={isDisabled}
                  />
                );
              }}
            />
            <Stack direction="row" width="100%" spacing={1}>
              <Button variant="contained" type="submit">
                {actionType === 'assign' ? 'Assign' : 'Unassign'}
              </Button>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default AssignRolesModal;
