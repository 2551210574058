import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import CustomModal from '../../../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  bulkDeleteDocs,
  deleteContractDoc,
  upload_file_in_s3_bucket,
  uploadDoc,
} from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleRefresh: VoidFunction;
  selectedRow: GridRowId[];
  allContracts: any[];
}

const SparkMD5 = require('spark-md5');

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6D264C' : '#308fe8',
  },
}));

const UploadSupportFile: React.FC<Props> = ({
  open,
  onClose,
  handleRefresh,
  selectedRow,
  allContracts,
}) => {
  const { control, watch, setValue } = useForm();

  const refcontract_id = watch('refcontract_id') || '';

  const disabledUploadFileField = useMemo(() => {
    if (refcontract_id?.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [refcontract_id]);

  const [uploadedContractFile, setUploadedContractFile] = useState<any>([]);
  const [uploadedContractData, setUploadedContractData] = useState<any[]>([]);
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(true);
  const [deletedContractId, setDeletedContractId] = useState<string>('');
  const [uploadedAllFile, setUploadedAllFile] = useState<any>({});
  const [allSelectedContractList, setAllSelectedContractList] = useState<any>(
    []
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const onUploadProgress = useCallback(
    (progressEvent: any, fileData: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = fileData;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedContractFile]
  );

  const { mutate: uploadFileInS3Bucket, isPending: s3BucketLoading } =
    useMutation({
      mutationKey: ['upload_contract_to_s3_bucket'],
      mutationFn: upload_file_in_s3_bucket,
      onSuccess: () => {
        enqueueSnackbar('Document uploaded successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to upload document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadDocument, isPending: isLoadingUploadDoc } = useMutation(
    {
      mutationKey: ['upload_support_file', uploadedContractFile],
      mutationFn: uploadDoc,
      onSuccess: (response: any) => {
        if (response) {
          setUploadedContractData((prevData) => [
            response?.contract,
            ...prevData,
          ]);
          setIsShowUploadField(false);
          Object.entries(uploadedAllFile)?.map(([key]) => {
            if (response?.contract?.file_name === uploadedAllFile[key]?.name) {
              const file = uploadedAllFile[key];
              if (file) {
                const onHandleFileProgress = {
                  onUploadProgress: (progressEvent: any) =>
                    onUploadProgress(progressEvent, file),
                };
                response?.presigned_url?.map((presigned_url: any) => {
                  uploadFileInS3Bucket({
                    presignedPostData: presigned_url,
                    file: file,
                    onHandleFileProgress: onHandleFileProgress,
                  });
                });
              }
            }
          });
        }
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errors?.[0];
        enqueueSnackbar(`${responseData || 'Failed to upload document!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  const { mutate: deleteUploadedDoc, isPending: isLoadingDeleteDoc } =
    useMutation({
      mutationKey: ['delete_support_doc', deletedContractId],
      mutationFn: deleteContractDoc,
      onSuccess: (data, deletedContractId) => {
        enqueueSnackbar('Document deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setUploadedContractData((prevData) =>
          prevData.filter((item) => item?.id !== deletedContractId)
        );
        handleCloseDeleteModal();
      },
      onError: () => {
        enqueueSnackbar('Failed to delete Document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  useEffect(() => {
    if (uploadedContractData?.length === 0) {
      setIsShowUploadField(true);
    }
  }, [uploadedContractData]);

  const { mutate: deleteBulkUploadedDocs, isPending: isLoadingBulkDelete } =
    useMutation({
      mutationKey: ['delete_bulk_support_docs'],
      mutationFn: bulkDeleteDocs,
      onSuccess: () => {
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Failed to delete all documents!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const handleFileUploadChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile: any = event.target.files;
    setUploadedAllFile(uploadedFile);
    Object.entries(uploadedFile)?.map(([key, value]) => {
      setUploadedContractFile({ [key]: value });
      const reader = new FileReader();
      const file = uploadedFile[key];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const file_ = {
            file_hash: hexHash,
            file_name: file.name,
            file_size: file.size,
            uploaded_type: 'Support Document',
            refcontract_id: refcontract_id,
          };
          uploadDocument(file_);
        };
      }
    });
  };

  const handleBulkDelete = () => {
    if (uploadedContractData?.length > 0) {
      const ids = uploadedContractData?.map((data: any) => data.id);
      deleteBulkUploadedDocs(ids);
    } else {
      onClose();
    }
  };

  const deleteUploadedDocById = (id: string) => {
    deleteUploadedDoc(id);
    setDeletedContractId(id);
  };

  const handleSaveAndClose = () => {
    onClose();
    handleRefresh();
  };

  const handleCloseModal = () => {
    if (
      isLoadingBulkDelete ||
      isLoadingDeleteDoc ||
      isLoadingUploadDoc ||
      s3BucketLoading
    ) {
      enqueueSnackbar("Don't close modal because process is going on!", {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      handleBulkDelete();
    }
  };

  useEffect(() => {
    if (allContracts?.length && selectedRow?.length) {
      const filterSelectedContracts = allContracts.filter((item: any) =>
        selectedRow.includes(item?.id)
      );
      setAllSelectedContractList(filterSelectedContracts);
    }
  }, [allContracts, selectedRow]);

  useEffect(() => {
    if (allSelectedContractList?.length > 0) {
      setValue('refcontract_id', selectedRow);
    }
  }, [selectedRow, allSelectedContractList]);

  return (
    <React.Fragment>
      <CustomModal
        maxWidth="md"
        title="Upload Support Document"
        open={open}
        handleClose={handleCloseModal}
      >
        <Stack spacing={2} mt="25px" width="550px">
          <RISelectComponent
            readOnly={true}
            name="refcontract_id"
            control={control}
            label="Link master contracts *"
            valueKey="id"
            labelKey="title"
            options={allSelectedContractList}
            isMultiselect={true}
            isShowDropDown={true}
            renderCustomComponent={(value: any, props) => (
              <Chip
                style={{
                  background: '#FFECF1',
                  padding: '15px 10px',
                  borderRadius: '5px',
                  color: '#6D264C',
                }}
                icon={<FolderOutlinedIcon style={{ color: '#6D264C' }} />}
                label={value?.title}
                {...props}
              />
            )}
          />

          {isShowUploadField && (
            <Controller
              name="upload_contract"
              control={control}
              render={() => (
                <TextField
                  variant="outlined"
                  label="Browse support document to upload *"
                  fullWidth
                  disabled
                  helperText="Supported file formats: PDF, DOCX"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor={`upload-doc`}>
                          <input
                            disabled={disabledUploadFileField}
                            type="file"
                            multiple
                            id={`upload-doc`}
                            style={{ display: 'none' }}
                            accept="pdf, docx"
                            onChange={(e) => handleFileUploadChanges(e)}
                          />
                          <IconButton
                            component="span"
                            disabled={disabledUploadFileField}
                          >
                            <FileUploadOutlinedIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}

          {isLoadingUploadDoc && (
            <Box sx={{ width: '100%', padding: '15px 0' }}>
              <LinearProgress />
            </Box>
          )}

          {uploadedContractData?.length > 0 &&
            uploadedContractData?.map((item: any, index: number) => (
              <Stack
                key={index}
                direction="column"
                spacing={1}
                paddingBottom="15px"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize="17px" fontWeight={600}>
                    {item?.file_name}
                  </Typography>
                  <LoadingButton
                    disabled={isLoadingBulkDelete}
                    size="small"
                    onClick={() => handleOpenDeleteModal(item?.id)}
                    loading={isLoadingDeleteDoc}
                  >
                    <DeleteOutlineIcon />
                  </LoadingButton>
                </Stack>
                <BorderLinearProgress variant="determinate" value={100} />
              </Stack>
            ))}
          <Stack direction="row" spacing={2} pt="15px" pb="10px">
            {uploadedContractData?.length === 0 && (
              <Button variant="outlined" onClick={handleCloseModal}>
                Close
              </Button>
            )}
            {uploadedContractData?.length > 0 && (
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  disabled={isLoadingBulkDelete || isLoadingDeleteDoc}
                  variant="contained"
                  loading={isLoadingUploadDoc || s3BucketLoading}
                  onClick={handleSaveAndClose}
                  style={{ fontSize: '15px', padding: '3px 15px' }}
                >
                  Save and close
                </LoadingButton>
                {!isShowUploadField && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setIsShowUploadField(true)}
                  >
                    Upload more
                  </Button>
                )}
                <LoadingButton
                  onClick={handleBulkDelete}
                  disabled={
                    isLoadingUploadDoc || isLoadingDeleteDoc || s3BucketLoading
                  }
                  startIcon={<CloseIcon />}
                  loading={isLoadingBulkDelete}
                  loadingPosition="end"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CustomModal>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteUploadedDocById(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default UploadSupportFile;
